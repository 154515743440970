import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line arrow-body-style
const withGoogleAnalytics = (WrappedComponent) => {
  return (props) => {
    const location = useLocation();

    useEffect(() => {
      const isAdminPage = location.pathname.startsWith("/admin");
      const existingScript = document.querySelector(
        `script[src="https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}"]`
      );

      const gtag = (...args) => {
        window.dataLayer.push(args);
      };
      
      if (process.env.REACT_APP_GA_TRACKING_ENABLED === '1' && !isAdminPage && !existingScript) {
        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
        script.async = true;
        document.body.appendChild(script);

        // Initialize Google Analytics
        window.dataLayer = window.dataLayer || [];
        gtag("js", new Date());
        gtag("config", process.env.REACT_APP_GA_TRACKING_ID);
      }

      return () => {
        if (isAdminPage && existingScript) {
          existingScript.remove();
        }
      };
    }, [location.pathname]);

    // eslint-disable-next-line react/jsx-filename-extension
    return <WrappedComponent {...props} />;
  };
};

export default withGoogleAnalytics;