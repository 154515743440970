import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as moment from 'moment';
import { setUser } from '../../../redux/actions/user.actions';
import webServices from '../../../services/webServices';
import RenderFormElement from '../../editable-sections/RenderFormElement';
import WdyhauList from '../../../appConstants/wdyhau';
import LoveGoals from '../../../appConstants/loveGoals';

const RELEATIONSHIP = [
  {
    value: 1,
    label: 'Single'
  },
  // {
  //   value: 2,
  //   label: 'Married'
  // },
  {
    value: 3,
    label: 'Divorced'
  }
];

// const DESIREDLOVE = [
//   {
//     value: 1,
//     label: 'Meet my life partner within 6 months'
//   },
//   {
//     value: 2,
//     label: 'Get married in less than 1 year'
//   },
//   {
//     value: 3,
//     label: 'Have kids within 2 years'
//   },
//   {
//     value: 4,
//     label: 'Temporary companion'
//   },
//   {
//     value: 5,
//     label: 'Dating, not thinking of marriage'
//   },
//   {
//     value: 6,
//     label: 'Other'
//   }
// ];

const heighRange = [];
for (let i = 4; i < 8; i++) {
  for (let j = 0; j < 12; j++) {
    heighRange.push({ value: `${i}.${j}`, label: `${i}' ${j}"` });
  }
}

const PersonalInfo = ({ userDetail, masterData, viewMode, forwardedRef }) => {
  const dispatch = useDispatch();
  const [formControls, setFormControls] = useState(null);

  const handleSaveDetails = (values) =>
    webServices.updateUserDetails(userDetail.id, values).then((response) => {
      if (response && response.success) {
        dispatch(setUser(response.data));
      }
      return response;
    });

  // useEffect(() => {
  //   webServices.getMasterData().then((response) => {
  //     if (response && response.success) {
  //       setHobbies(response.data.hobbies);
  //       // console.log('response.data', response.data);
  //     }
  //   });
  // });

  const handleRangeChange = (props) => async (event) => {
    const formControlVal = { ...formControls };
    formControlVal[props].value = event;
    setFormControls(formControlVal);
    // await webServices.updateUserDetails(userDetail.id, { [props]: event });
    // handleSaveDetails({ [props]: event });
  };

  const handleRangeChangeComplate = (props) => (val) => {
    handleSaveDetails({ [props]: val });
  };

  const checkIsAddressEditingAllowed = userDetailObj => {
    if (userDetailObj?.address_update_enable_date === null) {
      return true;
    }

    const addressUpdatedDate = moment(userDetailObj?.address_update_enable_date).subtract(3, 'months').format('YYYY-MM-DD');

    if (
      moment().format('YYYY-MM-DD') === addressUpdatedDate ||
      moment(userDetailObj?.address_update_enable_date).isBefore(moment(), 'day') ||
      moment(userDetailObj?.address_update_enable_date).isSame(moment(), 'day')
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!userDetail) {
      return;
    }

    setFormControls({
      gender: {
        label: 'Gender',
        type: 'switch',
        options: [
          { value: 'MAN', label: 'MAN' },
          { value: 'WOMAN', label: 'WOMAN' }
        ],
        value: {
          label: userDetail?.gender || '',
          value: userDetail?.gender || ''
        }
      },
      dob: {
        label: 'Date of Birth',
        type: 'dob',
        value: moment(userDetail?.dob).format('MMMM . DD . YYYY'),
        isLock: !viewMode
      },
      about: {
        label: 'About Me',
        type: 'text',
        value: userDetail?.about || '',
        placeholder: 'Enter about yourself',
        isTextArea: true
      },
      lookingFor: {
        label: 'Looking For',
        type: 'text',
        value: userDetail?.userDetails?.lookingFor || '',
        placeholder: 'Enter about looking for',
        isTextArea: true
      },
      relationshiptStatus: {
        label: 'Relationship Status',
        type: !viewMode ? 'dropdown' : '',
        options: RELEATIONSHIP,
        value: userDetail?.relationshiptStatus
          ? {
              label: userDetail?.relationshiptStatus,
              value: userDetail?.relationshiptStatus
            }
          : '',
        isLock: !viewMode
      },
      wdyhau: {
        label: 'How did your hear about us?',
        type: !viewMode ? 'dropdown' : '',
        options: WdyhauList,
        value: userDetail?.wdyhauLabel
          ? {
              label: userDetail?.wdyhauLabel,
              value: userDetail?.wdyhauValue
            }
          : '',
          isLock: !viewMode
      },
      loveGoal: {
        label: 'My Desired Outcome for Love - I’d likt to',
        type: 'dropdownText',
        options: LoveGoals,
        value: userDetail?.loveGoal || '',
        valueText: userDetail?.desiredLoveOther,
        nameField: 'desiredLoveOther',
        isMulti: true,
        isLock: !viewMode
      },
      residence: {
        label: 'Residence',
        type: 'residence',
        value1: userDetail?.countryLabel
          ? {
              label: userDetail?.countryLabel,
              value: userDetail?.countryValue
            }
          : '',
        value2: userDetail?.stateLabel
          ? {
              label: userDetail?.stateLabel,
              value: userDetail?.stateValue
            }
          : '',
        value3: userDetail?.cityLabel
          ? {
              label: userDetail?.cityLabel,
              value: userDetail?.cityLabel
            }
          : '',
        isDisabled: !checkIsAddressEditingAllowed(userDetail),
        helpNote: !checkIsAddressEditingAllowed(userDetail) ? `You can update your address from ${moment(userDetail?.address_update_enable_date).format('DD MMM, YYYY')}.` : '',
      },
      origin: {
        label: 'Origin',
        type: 'residence',
        value1: userDetail?.userDetails?.origin1
          ? {
              label: userDetail?.userDetails?.origin1?.label,
              value: userDetail?.userDetails?.origin1?.value
            }
          : '',
        value2: userDetail?.userDetails?.origin2
          ? {
              label: userDetail?.userDetails?.origin2?.label,
              value: userDetail?.userDetails?.origin2?.value
            }
          : '',
        value3: userDetail?.userDetails?.origin3
          ? {
              label: userDetail?.userDetails?.origin3?.label,
              value: userDetail?.userDetails?.origin3?.value
            }
          : '',
        isDisabled: true
      },
      hobbies: {
        label: 'Hobbies',
        type: 'dropdown',
        options: masterData.hobbies,
        value: userDetail?.hobbies || '',
        isMulti: true,
      },
      qualities: {
        label: 'Qualities',
        type: 'dropdown',
        options: masterData.qualites,
        value: userDetail?.qualities || '',
        isMulti: true,
      },
      values_col: {
        label: 'Values',
        type: !viewMode ? 'dropdown' : '',
        options: masterData.valuesDetails,
        value: userDetail?.values_col || '',
        isMulti: true,
        isLock: !viewMode
      },
      smoking: {
        label: 'Smoking',
        type: 'radio',
        options: [
          { value: '1', label: 'Never' },
          { value: '2', label: 'Occasionally' },
          { value: '3', label: 'Daily' }
        ],
        value: userDetail?.userDetails?.smokingValue
          ? {
              label: userDetail?.userDetails?.smokingLabel,
              value: userDetail?.userDetails?.smokingValue
            }
          : ''
      },
      drinking: {
        label: 'Drinking',
        type: 'radio',
        options: [
          { value: '1', label: 'Never' },
          { value: '2', label: 'Occasionally' },
          { value: '3', label: 'Daily' }
        ],
        value: userDetail?.userDetails?.drinkingValue
          ? {
              label: userDetail?.userDetails?.drinkingLabel,
              value: userDetail?.userDetails?.drinkingValue
            }
          : ''
      },
      children: {
        label: 'Kids',
        type: !viewMode ? 'radio' : '',
        options: [
          // { value: '1', label: "I don't have" },
          { value: '1', label: "I don't have & don't want any" },
          { value: '2', label: "I don't have but want some" },
          { value: '3', label: 'I have but want more' },
          { value: '4', label: "I have & don't want more" }
        ],
        value: userDetail?.userDetails?.childrenValue
          ? {
              label: userDetail?.userDetails?.childrenLabel,
              value: userDetail?.userDetails?.childrenValue
            }
          : '',
        isLock: !viewMode
      },
      how_many_kids: {
        label: 'How Many Kids',
        type:
          userDetail?.userDetails?.childrenValue > 3 && !viewMode
            ? 'range'
            : '',
        min: 0,
        max: 10,
        step: 1,
        lableType: '',
        value: userDetail?.userDetails?.how_many_kids || 0,
        isLock: !viewMode
      },
      height: {
        label: 'Height',
        type: 'range',
        min: parseInt(process.env.REACT_APP_MIN_HEIGHT, 10),
        max: parseInt(process.env.REACT_APP_MAX_HEIGHT, 10),
        step: 0.1,
        lableType: '""',
        isShowLevelPoints: false,
        value: userDetail?.height || 4
      },
      annualIncome: {
        label: 'Annual income ($)',
        type: !viewMode ? 'range' : '',
        min: 0,
        max: 500,
        step: 10,
        lableType: 'K',
        value: userDetail?.anual_income || 0,
        isLock: !viewMode
      },
      ethnicGroup: {
        label: 'Cultural Group',
        type: 'dropdown',
        options: masterData.cultrual,
        value: userDetail?.userDetails?.ethnicGroupValue
          ? {
              label: userDetail?.userDetails?.ethnicGroupLabel,
              value: userDetail?.userDetails?.ethnicGroupValue
            }
          : ''
      },
      languages: {
        label: 'Ethnic Group / Tribe / Cultural Subgroup',
        type: 'dropdown',
        options: masterData.languages,
        value: userDetail?.userDetails?.languages || '',
        // isMulti: true,
        limit: 2
      },
      spirituality: {
        label: 'Spirituality',
        type: 'dropdown',
        options: masterData.faith,
        value: userDetail?.userDetails?.spiritualityValue
          ? {
              label: userDetail?.userDetails?.spiritualityLabel,
              value: userDetail?.userDetails?.spiritualityValue
            }
          : ''
      }
    });
  }, [userDetail, masterData]);

  return (
    <>
      {/* {console.log('userDetail', userDetail)} */}
      {formControls &&
        Object.keys(formControls).map((formControlName) => (
          <RenderFormElement
            ref={forwardedRef}
            isViewMode={viewMode}
            key={formControlName}
            formControlName={formControlName}
            {...formControls[formControlName]}
            saveDetails={handleSaveDetails}
            handleRangeChange={handleRangeChange(formControlName)}
            handleRangeChangeComplate={handleRangeChangeComplate(
              formControlName
            )}
          />
        ))}
    </>
  );
};

export default PersonalInfo;
