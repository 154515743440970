import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../redux/actions/user.actions';
import AppConstants from '../appConstants';
import webServices from '../services/webServices';

const NoticeComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);

  const handleBtnClick = async (status) => {
    const apiResponse = await webServices.updateMatchQueueCountryPreference({ status });
    if (apiResponse?.data) {
      dispatch(setUser({ ...current, ...apiResponse?.data }));
      
      history.push(AppConstants.routes.afterLogin);
    }
  };

  return (
    <>
      <div className='chnageCountry_bg'>
        <div className='container-fluid'>
          <div className='innerContent'>
            <h1>Meet Exciting Matches Near You Who Come from Other African Countries
            </h1>
            <div className='d-flex'>
              <button className="btn theme-btn-purple" type="button" onClick={() => handleBtnClick(1)}>
                Yes
              </button>
              <button className="btn theme-btn-purple bg-white text-black" type="button" onClick={() => handleBtnClick(0)}>
                No
              </button>
            </div>
            <div className='mobileSrroll'>
              <p>
                We currently don&apos;t have matches from your preferred origin country, but great news! We expect new profiles from that location soon.</p>
              <p>
                In the meantime, why wait? Open the door to exciting possibilities today by meeting high-quality matches near you from
                other African countries.
              </p>
              <p>
                Your Call, Your Connection
              </p>
              <div className='large_btn'>
                <button className="btn large-btn-green" type="button" onClick={() => handleBtnClick(1)}>
                  I want to explore matches near me who come from other African countries
                </button>
                <button className="btn large-btn-orange" type="button" onClick={() => handleBtnClick(0)}>
                  I’d like to wait for matches from my preferred origin country
                </button>
              </div>
              <div className='caption_bottom'>The connection you’ve been waiting for might just be one step away—start exploring today!</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeComponent;