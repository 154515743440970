import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/actions/user.actions';
import webServices from '../../services/webServices';
import { pageTitle } from '../../redux/actions/home.actions';
import Spinner from '../general/spinner';
import PayPalCheckoutPage from '../../services/PayPalCheckout';
import offer from '../../assets/images/Offer.svg';
import PlanMoreInfoModalComponent from './PlanMoreInfoModalComponent';
import AuthorizeNetPayment from '../../services/AuthorizeNetPayment';

const PlansComponent = () => {
  const bottomRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isSpinner, setIsSpinner] = useState(true);
  const [showPlanMoreInfoModal, setShowPlanMoreInfoModal] = useState(false);
  const { current: userData } = useSelector((state) => state.user);
  const [isSubscriptionPurchased, setIsSubscriptionPurchased] = useState(userData.plan_subscription_id !== null);

  const getPlanPrice = plan => plan.price.toFixed(2);

  const displayPlanPrice = (plan) => {
    const planPrice = getPlanPrice(plan).split('.');
    return `$${planPrice[0]}.<sup>${planPrice[1]}</sup>`;
  };

  useEffect(async () => {
    dispatch(pageTitle('Plans'));

    const resp = await webServices.getUserDetail();
    if (resp && resp.success === true) {
      setIsSubscriptionPurchased(resp.data.plan_subscription_id !== null);
      dispatch(setUser(resp.data));
    }

    (async () => {
      const response = await webServices.getPlans();
      if (response && response.success) {
        setPlans(response.data);
        setTimeout(() => {
          setIsSpinner(false);
        }, 400);
      }
    })();
  }, []);

  useEffect(() => {
    if (!plans.length) {
      return;
    }
    if (!location?.state?.planDetail) {
      setSelectedPlan(
        plans.reduce(
          (acc, value) => (acc.numberOfDays > value.numberOfDays ? acc : value),
          {}
        )
      );
    }
  }, [plans]);

  const handlePlanClick = (event, plan) => {
    event.preventDefault();
    setSelectedPlan(plan);
  };

  const handelPlanMoreInfoPopupCloseBtn = () => {
    setShowPlanMoreInfoModal(false);
  };

  const handelPlanMoreInfoPopupShowIcon = () => {
    setShowPlanMoreInfoModal(true);
  };

  useEffect(() => {
    if (location?.state?.planDetail) {
      setSelectedPlan(location.state.planDetail);
    }
  }, [location.state]);

  return (
    <>
      <div className="full-page planWrapper">
        <div className="container">
          {isSpinner ? (
            <Spinner active={isSpinner} />
          ) : (
            <>
              <h1 className="text-center text-lg-center text-md-center">
                Simple, Transparent Pricing
              </h1>

              {!isSubscriptionPurchased && (
                <div className="animates_wrapper">
                  <div className="sale glowWrap">
                    {' '}
                    <img
                      src={offer}
                      className="img-rose-fluid"
                      alt="Lovrican rose"
                    />{' '}
                    <span className="">
                      <span className="sun_font">I Love Africa </span>2024  Sale
                    </span>
                    <img
                      src={offer}
                      className="img-rose-fluid"
                      alt="Lovrican rose"
                    />
                  </div>
                </div>
              )}

              <p className="sale_details">
                We keep our pricing simple. You only pay $9.<sup>99</sup> per month.

                <span className='lear_more'>
                  Learn more
                  <span className='dot_wrapper ml-2' href='/' onClick={handelPlanMoreInfoPopupShowIcon} onKeyDown={() => { }} role='presentation'>
                    <span className='dot' />
                    <span className='dot' />
                    <span className='dot' />
                  </span>
                </span>

              </p>
              <div className="mid-container-outer" ref={bottomRef}>
                <div className="mid-container panConatiner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="plan-details">
                        <div className="nav flex-column nav-pills">
                          {!!plans.length &&
                            plans.map((plan) => (
                              <a
                                className={`nav-link ${selectedPlan.id === plan.id ? 'active' : ''
                                  }`}
                                data-toggle="pill"
                                href="/"
                                onClick={(event) => handlePlanClick(event, plan)}
                                key={plan.id}
                              >
                                <div className="d-flex justify-content-between align-items-start flex-wrap flex-md-nowrap">
                                  <div className="lft d-flex align-items-center">
                                    <div className="icon mr-3">
                                      <i
                                        className="fa fa-circle-thin"
                                        aria-hidden="true"
                                      />
                                      <i
                                        className="fa fa-check-circle"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="plan_title">
                                      <h3>{plan.name}</h3>
                                    </div>
                                  </div>
                                  <div className="mr-3">{
                                    isSubscriptionPurchased ? (
                                      <div className='subPlan'><i className="fa fa-check mr-2" aria-hidden="true" />Subscribed</div>
                                    ) : ''
                                  }</div>
                                  <div className="plan-center d-none">
                                    <span className="month">
                                      {plan.numberOfDays < 30
                                        ? '1-week'
                                        : `${(plan.numberOfDays / 30).toFixed(
                                          0
                                        )}-month`}{' '}
                                      access
                                    </span>
                                    {(
                                      ((plans[0].price - plan.price) * 100) /
                                      plans[0].price
                                    ).toFixed(0) > 0 && (
                                        <div className="bottom d-none">
                                          <div
                                            className={`save ${plan.name.toLowerCase()}`}
                                          >
                                            Save{' '}
                                            {(
                                              ((plans[0].price - plan.price) *
                                                100) /
                                              plans[0].price
                                            ).toFixed(0)}
                                            %{/* </span> */}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  <div className="rgt">
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <div className="price" dangerouslySetInnerHTML={{ __html: displayPlanPrice(plan) }} />
                                    <div className='month mb-2'>per month</div>
                                  </div>
                                  {/* <div className="rgt d-none">
                                    <div className="price">
                                      ${plan.price} / Week <br />
                                      <span>
                                        Total = $
                                        {(
                                          plan.price *
                                          (plan.numberOfDays / 7)
                                        ).toFixed(2)}
                                      </span>
                                    </div>
                                  </div> */}
                                </div>
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="white-border" />
                <div id="paypal-button-container" />
                <div className="mid-container mt-4 panConatiner">
                  <div className="mobile_plan justify-content-center text-center mobileFooter">
                    {
                      !isSubscriptionPurchased
                        ? (
                          <AuthorizeNetPayment
                            planId={selectedPlan.id}
                            planAmount={getPlanPrice(selectedPlan)}
                            planType='SUBSCRIPTION_PLAN'
                            selectedPlan={selectedPlan}
                          />
                        )
                        : (
                          <div />
                        )
                    }
                  </div>

                  <div className="mobile_plan justify-content-center text-center mobileFooter d-none">
                    <PayPalCheckoutPage
                      planId={selectedPlan.id}
                      amount={getPlanPrice(selectedPlan)}
                      selectedPlan={selectedPlan}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <PlanMoreInfoModalComponent show={showPlanMoreInfoModal} handelClose={handelPlanMoreInfoPopupCloseBtn} />
    </>
  );
};

export default PlansComponent;
