import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../redux/actions/home.actions';
import webServices from '../services/webServices';
import authService from '../services/authServices';

const ACCOUNT_DELETION_REASONS_ARR = [
  {
    id: 1,
    value: "I met my soulmate on Lovrican",
    text: "I met my soulmate on Lovrican"
  },
  {
    id: 2,
    value: "I met my soulmate outside Lovrican",
    text: "I met my soulmate outside Lovrican"
  },
  {
    id: 3,
    value: "I needed more privacy for my account",
    text: "I needed more privacy for my account"
  },
  {
    id: 4,
    value: "I encountered individuals with whom I have history",
    text: "I encountered individuals with whom I have history"
  },
  {
    id: 5,
    value: "I was receiving too many emails",
    text: "I was receiving too many emails"
  },
  {
    id: 6,
    value: "I had difficulty using the app",
    text: "I had difficulty using the app"
  },
  {
    id: 7,
    value: "other",
    text: "Other (please specify)"
  }
];

const AccountDeleteFeedbacksPage = () => {
  const dispatch = useDispatch();
  const { logout } = authService;
  const history = useHistory();

  const [values, setValues] = useState({
    reason: '',
    otherReason: '',
    improvement: '',
    feedbackType: 'DELETE'
  });

  const [errors, setErrors] = useState({
    reasonError: '',
    otherReasonError: '',
    improvementError: ''
  });

  useEffect(async () => {
    dispatch(pageTitle('Account'));
  }, []);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setValues(prevValues => ({ ...prevValues, reason: value }));
    setErrors(prevValues => ({ ...prevValues, reasonError: '', otherReasonError: '' }));
  };

  const validations = () => {
    let isError = false;
    const errorsMsg = { ...errors };
    if (values.reason === '') {
      isError = true;
      errorsMsg.reasonError = 'Please choose any reason.';
    }

    if (values.otherReason === '' && values.reason === 'other') {
      isError = true;
      errorsMsg.otherReasonError = 'This field is required.';
    }

    if (values.improvement === '') {
      isError = true;
      errorsMsg.improvementError = 'This field is required.';
    }

    setErrors(errorsMsg);

    return isError;
  };

  const handleClickClose = async () => {
    if (!validations()) {
      await webServices.createFeedbacks(values);
      await webServices.updateUserSettings({
        account_delete: 'DELETED'
      });
      toast.success('Your account has been deleted successfully!');
      logout(history);
    }
  };

  return (
    <>
      <div className="inner-wrapper-newdesign">
        <div className="reward">
          <h2 className="text-center">Closing your account</h2>
          <p className="text-center">
            We’re sorry to see you leave. We appreciate your business and value
            your feedback.
          </p>
          <div className="reward_container">
            <div className="head">
              <p className="text-theme m-0">
                I want to close my <br />
                account because...
              </p>
            </div>
            <div className="body">
              <div className="customeRadio_Inner">
                {ACCOUNT_DELETION_REASONS_ARR.map(el => (
                  <div key={el.id}>
                    <input
                      type="radio"
                      id={`reward${el.id}`}
                      name="radio-group"
                      value={el.value}
                      onChange={handleInputChange}
                    />
                    <label htmlFor={`reward${el.id}`}>{el.text}</label>
                  </div>
                ))}
              </div>
              <div className="mt-3" style={{ color: '#f74545' }}>
                {errors.reasonError}
              </div>
            </div>

            {values?.reason === 'other' && (
              <div className="body">
                <div className="feedback_field">
                  <div className="label">I did not like ...</div>
                  <textarea
                    className="form-control third"
                    name="review"
                    rows="5"
                    placeholder="Tell us what you like about Lovrican"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        otherReason: e.target.value
                      });
                      setErrors({ ...errors, otherReasonError: '' });
                    }}
                  />
                  <div className="mt-3" style={{ color: '#f74545' }}>
                    {errors.otherReasonError}
                  </div>
                </div>
              </div>
            )}

            <div className="head">
              <p className="text-theme m-0">
                What single action could we have <br />
                taken to retain your business?
              </p>
            </div>
            <div className="body">
              <div className="feedback_field">
                <div className="label">I wish Lovrican ...</div>
                <textarea
                  className="form-control second"
                  name="review"
                  rows="5"
                  placeholder="Tell us what you like about Lovrican"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      improvement: e.target.value
                    });
                    setErrors({ ...errors, improvementError: '' });
                  }}
                />
                <div className="mt-3" style={{ color: '#f74545' }}>
                  {errors.improvementError}
                </div>
              </div>
            </div>
            <span
              className="submit-btn"
              role="button"
              aria-hidden="true"
              onClick={handleClickClose}
            >
              Delete Account
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDeleteFeedbacksPage;
