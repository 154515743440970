const AppConstants = {
  OTP_ATTEMP_LIMIT: 3,
  APP_URL: window.location.origin,

  routes: {
    home: '/',
    home2: '/:isoCode([A-Za-z]{2})',
    about: '/about-us',
    login: '/login',
    // password: '/reset-password',
    forgotPassword: '/reset-password',
    register: '/register',
    afterLogin: '/match-queue',
    publicProfile: '/public-profile',
    editProfile: '/edit-profile',
    chat: '/chat',
    editProfilePhotos: '/edit-profile-photos',
    settings: '/settings',
    plansView: '/plan-view',
    billingDetails: '/billing-details',
    plans: '/plans',
    rosePlans: '/rose-plans',
    likesPlans: '/likes-plans',
    order: '/order',
    matchQueue: '/my-matches',
    contact: '/contact-us',
    faq: '/faq',
    viewProfile: '/view-profile',
    viewProfilePhotos: '/view-profile-photos',
    registerSuccess: '/register-success',
    registerSections: '/register-sections',
    congratulations: '/congratulations',
    accountSuccess: '/account-success',
    profileSection1: '/profile-section1',
    section1Success: '/section1-success',
    profileSection2: '/profile-section2',
    roseHistory: '/rose-history',
    likeHistory: '/like-history',
    gift: '/gift',
    affiliate: {
      home: '/affiliate',
      accountType: '/affiliate/account-type',
      register: '/affiliate/register',
      registerSection: '/affiliate/register-sections',
      login: '/affiliate/login',
      dashboard: '/affiliate/dashboard',
      referals: '/affiliate/referals',
      earnings: '/affiliate/earnings',
      payments: '/affiliate/payments',
      profile: '/affiliate/profile',
      settings: '/affiliate/settings',
      contactsUs: '/affiliate/contacts',
    },
    affiliateSteps3: '/affiliate-steps3',
    AffiliateRegSuccess: '/affiliate-Success',
    emailVerification: '/email-verify',
    rewardFeedbacks: '/reward-feedback',
    feedbacks: '/feedbacks',
    payPalCheckout: "/paypal-checkout",
    paymentSuccess: "/payment-success",
    paymentFail: "/payment-fail",
    admin: {
      login: '/admin/login',
      dashboard: '/admin/recent-profiles',
      premium_profiles: '/admin/premium-profiles',
      all_profiles: '/admin/all-profiles',
      approved_profiles: '/admin/approved-profiles',
      suspended_profiles: '/admin/suspended-profiles',
      hidden_profiles: '/admin/hidden-profiles',
      rejected_profiles: '/admin/rejected-profiles',
      deleted_profiles: '/admin/deleted-profiles',
      requested_info_profiles: '/admin/requested-info-profiles',
    },
    matchQueueCountryPreference: '/match-queue/country-preference',
  },
  PAGINATION: {
    term: '',
    page: 1,
    limit: 10
  },
  COUNTRIES_TO_REMOVE_ARR: ["AX","AS","AI","AQ","AW","BV","IO","KY","CX","CC","CK","FK","FO","GF","PF","TF","GI","GL","GP","GU","GG","HM","JE","MO","IM","MQ","YT","MS","BQ","NC","NU","NF","MP","PS","PN","PR","RE","SH","PM","BL","MF","GS","SJ","TK","TC","UM","VA","VG","VI","WF","EH","CW","SX"],

  USER_APPROVAL_PREMIUM_PLAN_DURATION: '3 Days',
};

export default AppConstants;
