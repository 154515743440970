import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../redux/actions/home.actions';
import { getUserPlansDetails } from '../redux/actions/user.actions';
import AppConstants from '../appConstants';
import heartIcon from '../assets/images/heart.svg';
import QueueCard from '../components/general/queueCard';
import webServices from '../services/webServices';
import chatImg from '../assets/images/sidebar-img1.svg';
import verfied from '../assets/images/verified.svg';
import ageIcon from '../assets/images/Cake.svg';
import notVerfied from '../assets/images/verified_Grey.svg';
import RightSidebar from '../layouts/partial/rightSidebar';
import Spinner from '../components/general/spinner';
import RoseSendPopup from './roseSendPopup';
import rose from '../assets/images/rose.svg';
import NoActivePlanPopup from './noActivePlanPopup';
import RoseExceedPopup from './roseExceedPopup';
import DailyLikeRemainderForRosePopup from './dailyLikeRemainderForRosePopup';
import { authCall } from '../services';
import VerifiedNotificationPopup from './verifiedNotificationPopup';
import DailyLikeRemainderPopup from './dailyLikeRemainderPopup';
import PlanNotPurchasedPopup from './planNotPurchasedPopup';

const MatchQueuePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const userDetails = useSelector(
  //   (state) => (state.user && state.user.current) || null
  // );
  const { planDetails, current } = useSelector((state) => state.user);
  const [matchData, setMatchData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [myMatchedCount, setMyMatchedCount] = useState(0);
  const [isSpinner, setIsSpinner] = useState(true);
  const [showRoseSend, setShowRoseSend] = useState(false);
  const [roseSent, setRoseSent] = useState(false);
  const [showActivePlan, setShowActivePlan] = useState(false);
  const [roseExceed, setRoseExceed] = useState(false);
  const [roseCountSend, setRoseCountSend] = useState(0);
  const [myTotalRoses, setMyTotalRoses] = useState(0);
  const [dailyLikeRemainderRose, setDailyLikeRemainderRose] = useState(false);
  const [dailyLikeRemainder, setDailyLikeRemainder] = useState(false);
  const [showPlanNotPurchased, setShowPlanNotPurchased] = useState({ status: false, popupType: 1 });

  const handleClosePlanNotPurchased = () => setShowPlanNotPurchased({ status: false, popupType: 1 });

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const matchedList = async () => {
    const resp = await webServices.getMyMatches();
    if (resp && resp.success === true) {
      setMatchData(resp.data);
      setSelectedData(resp.data[0]);
      setTimeout(() => {
        setIsSpinner(false);
      }, 400);
    }
  };
  const getMatchCount = async () => {
    const resp = await webServices.getMatchCount();
    if (resp && resp.success === true) {
      setMyMatchedCount(resp.data.count);
    }
  };

  useEffect(async () => {
    matchedList();
    dispatch(pageTitle('My Matches'));
    getMatchCount();
    dispatch(getUserPlansDetails());
  }, []);

  const handleClickUsers = (details) => {
    setSelectedData(details);
  };

  const handleCloseDailyLikeRemainder = () => setDailyLikeRemainder(false);

  const handleActionChat = async (details, e) => {
    e.stopPropagation();
    const resp = await authCall('user/userCheckPermission', { action: 'chat' });
    if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    } else if (resp.data === 'planNotPurchased') {
      setShowPlanNotPurchased({ status: true, popupType: (resp.otherData.totalMatchCounts > 0 ? 2 : 1) });
    } else {
      history.push({
        pathname: AppConstants.routes.chat,
        state: { userDetail: details }
      });
    }
    // if (!planDetails.haveActivePlan) {
    //   setShowActivePlan(true);
    //   return;
    // }
    // history.push({
    //   pathname: AppConstants.routes.chat,
    //   state: { userDetail: details }
    // });
  };

  const handleActionView = (details) => {
    history.push({
      pathname: AppConstants.routes.viewProfile,
      state: { userId: details.user_id }
    });
  };

  const handleConfirmFinal = async () => {
    const response = await webServices.userChatActions({
      toId: selectedData.user_id,
      status: 'Delete'
    });
    if (response && response.success) {
      toast.success(response.message);
      setShowConfirmation(false);
      matchedList();
      getMatchCount();
    }
  };

  const handleSendRose = async (e) => {
    e.stopPropagation();
    const resp = await authCall('user/userCheckPermission', { action: 'rose' });
    if (resp.data === 'roseAvailable') {
      setShowRoseSend(true);
    } else if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else if (resp.data === 'showDailyLikeRemainderRose') {
      setDailyLikeRemainderRose(true);
    } else if (resp.data === 'showDailyLikeRemainder') {
      setDailyLikeRemainder(true);
    } else if (resp.data === 'showRosePurchase') {
      setShowRoseSend(true);
    } else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    } else {
      setShowRoseSend(true);
    }
  };

  const handleCloseRoseSend = () => {
    setShowRoseSend(false);
    setRoseSent(false);
  };

  const handleCloseRoseExceed = () => setRoseExceed(false);
  const handleCloseDailyLikeRemainderRose = () =>
    setDailyLikeRemainderRose(false);

  const handleConfirmRoseSend = async (response) => {
    if (response.roses) {
      const { data, success } = await webServices.getMyRoses();
      if ((success && data.my_roses < 1) || data.my_roses < response.roses) {
        setShowRoseSend(false);
        setRoseExceed(true);
        setRoseCountSend(response.roses);
        setMyTotalRoses(data.my_roses);
      } else {
        const result = await webServices.userLike({
          userId: selectedData.user_id,
          action: 'roseForMatched',
          roses: response.roses
        });
        if (result.success) {
          setRoseSent(true);
        } else {
          setShowRoseSend(false);
        }
      }
    }
  };

  const handleCloseActivePlan = () => setShowActivePlan(false);

  const handleCheckVerified = (e) => {
    e.stopPropagation();
    setVerifyMsg(
      current?.status !== 'ACTIVE' || current?.phone === null || false
    );
  };

  return (
    <>
      <div className="main-container d-none">
        <div className="matcue-top text-center kkk">
          <img className="top-img" src={heartIcon} alt="" />
          <h2>My Matches</h2>
          <p>
            Lovrican recognizes that culture and place of origin is a major
            factor in selecting a mate especially in the value-driven
          </p>
        </div>
        {matchData.map((prop) => (
          <QueueCard key={prop.user_id} data={prop} />
        ))}
      </div>

      <div className="inner-wrapper-newdesign">
        {isSpinner ? (
          <Spinner active={isSpinner} />
        ) : (
          <div className="row myMaches-mobile">
            <div className="col-md-12">
              <div className="largeScreen">
                <div className="d-flex justify-content-between">
                  <div className="w-100 MatchesWrapper">
                    <div className="row">
                      <div className="col-md-3 col-lg-2">
                        <div className="leftScroll">
                          {matchData.map((prop) => (
                            <div
                              className="imgBox"
                              key={prop.user_id}
                              onClick={() => handleClickUsers(prop)}
                              aria-hidden="true"
                            >
                              <span
                                className={`${prop.is_online ? 'active' : ''}`}
                              />
                              <div className="imgWrapper">
                                {prop?.rosesSentToMe > 0 && (
                                  <div className="smallRpseWrapper">
                                    <div className="roseSection">
                                      <img src={rose} alt="Lovrican rose" />
                                      <div className="count">
                                        {prop?.rosesSentToMe}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <img
                                  src={`${process.env.REACT_APP_PIC_BASE_URL}${prop.profile_image
                                      ? prop.profile_image
                                      : 'no_Image.svg'
                                    }`}
                                  className="send-icon"
                                  alt={`${process.env.REACT_APP_PIC_BASE_URL}${prop.profile_image
                                      ? prop.profile_image
                                      : 'no_Image.svg'
                                    }`}
                                />
                                {/* <p className="new">New</p> */}
                                <p className="name">{prop.user_name}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-9 col-lg-10">
                        {selectedData && (
                          <div className="d-flex flex-lg-row flex-column">
                            <div
                              className="image-slider-box"
                              aria-hidden="true"
                              onClick={() => handleActionView(selectedData)}
                              style={{ cursor: 'pointer' }}
                            >
                              {selectedData.rosesSentToMe > 0 && (
                                <div className="rose-container">
                                  <div className="rose-inner row ">
                                    {(() => {
                                      const roseData = [];
                                      for (
                                        let i = 1;
                                        i <=
                                        (selectedData.rosesSentToMe > 12
                                          ? 11
                                          : selectedData.rosesSentToMe);
                                        i++
                                      ) {
                                        roseData.push(
                                          <div
                                            className="roseBox col-6"
                                            key={i}
                                          >
                                            <div className="rose-circle">
                                              {' '}
                                              <img
                                                src={rose}
                                                alt="Lovrican rose"
                                              />
                                            </div>
                                          </div>
                                        );
                                      }
                                      return roseData;
                                    })()}
                                    {selectedData.rosesSentToMe > 12 && (
                                      <div className="roseBox col-6 ">
                                        <div className="rose-circle position-relative">
                                          <img src={rose} alt="Lovrican rose" />
                                          <div className="moreOption">
                                            +{selectedData.rosesSentToMe - 12}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              <img
                                className="slider-bck-img"
                                src={`${process.env.REACT_APP_PIC_BASE_URL}${selectedData.profile_image
                                    ? selectedData.profile_image
                                    : 'no_Image.svg'
                                  }`}
                                alt={`${process.env.REACT_APP_PIC_BASE_URL}${selectedData.profile_image
                                    ? selectedData.profile_image
                                    : 'no_Image.svg'
                                  }`}
                              />
                              <div className="row justify-content-between chatBoxWrapper">
                                <div className="col-12">
                                  <div className="chatBox d-none">
                                    <p className="heightCustomer">
                                      {selectedData.user_age} •{' '}
                                      {selectedData.height
                                        ? `${selectedData.height.replace(
                                          '.',
                                          "'"
                                        )}"`
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="chatBox">
                                    <div
                                      className="chatTool float-left"
                                      aria-hidden="true"
                                      onClick={(e) => handleSendRose(e)}
                                      type="button"
                                    >
                                      <img
                                        src={rose}
                                        className="send-icon mr-2"
                                        alt=""
                                      />
                                      <p>
                                        <span className="innerChat position-relative">
                                          Send Roses
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="chatBox">
                                    <div
                                      className="chatTool float-right"
                                      aria-hidden="true"
                                      onClick={(e) =>
                                        handleActionChat(selectedData, e)
                                      }
                                      type="button"
                                    >
                                      <img
                                        src={chatImg}
                                        className="send-icon"
                                        alt=""
                                      />
                                      <p>
                                        <span className="innerChat position-relative">
                                          Chat
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="user-detail-box my_matches_container">
                              <div
                                className="filters-line text-right d-none"
                                aria-hidden="true"
                                onClick={() => setShowConfirmation(true)}
                              >
                                <span>Delete User</span>
                              </div>
                              <div className="usr-info-box ">
                                <div className="filter" />
                                <h5
                                  className="cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() => handleActionView(selectedData)}
                                >
                                  <span className="match_name">
                                    {selectedData.user_name}
                                  </span>
                                  {/* <span className='dotAge'>&#8226;</span>{selectedData.user_age} */}
                                  <img
                                    src={
                                      selectedData?.status === 'ACTIVE' &&
                                        selectedData?.phone !== null
                                        ? verfied
                                        : notVerfied
                                    }
                                    className="img-fluid verifid_img"
                                    style={{
                                      cursor:
                                        current?.status === 'ACTIVE' &&
                                          current?.phone !== null
                                          ? 'default'
                                          : 'pointer'
                                    }}
                                    alt="arrow icon"
                                    aria-hidden="true"
                                    onClick={(e) => handleCheckVerified(e)}
                                  />
                                </h5>
                                {selectedData.user_occupation ? (
                                  <p className="occupation">
                                    {selectedData.user_occupation}
                                    {selectedData?.companyName
                                      ? ` at ${selectedData?.companyName}`
                                      : ''}
                                  </p>
                                ) : (
                                  <p className="about-dec missting_section_match">
                                    Occupation to be added
                                  </p>
                                )}

                                <p className="address pt-3">
                                  {selectedData.user_add}
                                </p>
                                {/* <p className='country_name'>{selectedData.country}</p> */}
                                <div>
                                  <p className="prf_height age_icon">
                                    <img src={ageIcon} alt="/" />{' '}
                                    {selectedData.user_age}
                                  </p>
                                  <p className="prf_height">5’ 6”</p>
                                </div>
                                {selectedData.education ? (
                                  <p className="destination pt-3">
                                    {selectedData.education}
                                  </p>
                                ) : (
                                  <p className="about-dec missting_section_match pt-3">
                                    Education to be added
                                  </p>
                                )}

                                <div className="about-me-box pt-3">
                                  <p className="head">Height:</p>

                                  {selectedData.height ? (
                                    <p className="about-dec">
                                      {selectedData.height
                                        ? `${selectedData.height.replace(
                                          '.',
                                          "'"
                                        )}"`
                                        : ''}
                                    </p>
                                  ) : (
                                    <p className="about-dec missting_section_match">
                                      Height to be added
                                    </p>
                                  )}
                                </div>

                                <div className="about-me-box pt-3">
                                  <p className="head">About Me:</p>

                                  {selectedData.about_us ? (
                                    <p className="about-dec">
                                      {selectedData.about_us}
                                    </p>
                                  ) : (
                                    <p className="about-dec missting_section_match">
                                      Intro summary to be added
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="menubar-wrapper">right</div> */}
                  <div className="d-none">
                    <RightSidebar matchCount={myMatchedCount} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        className="filter-model PlanStatusModel"
        show={showConfirmation}
        onHide={showConfirmation}
        centered
      // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">Delete this user?</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deleting {selectedData.user_name} will remove them from your match
            list and you will no longer be able to communicate with them.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleConfirmFinal()}
            >
              Delete user
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <RoseSendPopup
        modalStatus={showRoseSend}
        handleClose={handleCloseRoseSend}
        userData={selectedData}
        handleConfirmRoseSend={handleConfirmRoseSend}
        roseSent={roseSent}
        fromMatched
        handleCloseCancel={handleCloseRoseSend}
      />

      <NoActivePlanPopup
        modalStatus={showActivePlan}
        handleClose={handleCloseActivePlan}
      />

      <RoseExceedPopup
        modalStatus={roseExceed}
        userData={selectedData}
        // userDetails={current}
        roseCountSend={roseCountSend}
        myTotalRoses={myTotalRoses}
        handleClose={handleCloseRoseExceed}
        fromMatched
      />

      <DailyLikeRemainderForRosePopup
        modalStatus={dailyLikeRemainderRose}
        currentPlan={planDetails}
        userDetails={selectedData}
        handleClose={handleCloseDailyLikeRemainderRose}
      />

      <DailyLikeRemainderPopup
        modalStatus={dailyLikeRemainder}
        currentPlan={planDetails}
        userDetails={current}
        handleClose={handleCloseDailyLikeRemainder}
      />

      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={current}
      />

      <PlanNotPurchasedPopup
        modalStatus={showPlanNotPurchased.status}
        handleClose={handleClosePlanNotPurchased}
        popupType={showPlanNotPurchased.popupType}
      />
    </>
  );
};

export default MatchQueuePage;
