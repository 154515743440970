import React, { useEffect, useState } from 'react';
// import toastr from 'toastr';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppConstants from '../appConstants';
import { setUser } from '../redux/actions/user.actions';
import { authCall } from '../services';
import authService from '../services/authServices';
import arrowIcon from '../assets/images/arrow-circle.svg';
import HomeHeaderMobile from '../layouts/partial/homeHeaderMobile';
import Spinner from '../components/general/spinner';

function LoginPageComponent() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSpinner, setIsSpinner] = useState(true);
  const [values, setValues] = React.useState({
    password: '',
    email: '',
    request: true
  });

  const [errorValues, setErrorValues] = React.useState({
    emailError: '',
    passwordError: ''
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    setErrorValues({ ...errorValues, [`${name}Error`]: '' });
  };

  const formValidation = () => {
    let isValid = true;
    const errors = { ...errorValues };
    if (!values.email) {
      errors.emailError = 'The email field is required.';
      isValid = false;
    }
    if (!values.password) {
      errors.passwordError = 'The password field is required.';
      isValid = false;
    }
    setErrorValues(errors);
    return isValid;
  };

  const submitForm = async () => {
    const isValid = formValidation();
    if (isValid && values.request) {
      setValues({ ...values, request: false });
      const request = {
        password: values.password,
        username: values.email
      };
      const resp = await authCall('auth/login', request);
      if (resp && resp?.success === true) {
        authService.setToken(resp.data);
        dispatch(setUser(resp.data.user));
        // set selected county in localstorage
        window.localStorage.setItem(
          'selectedCountry',
          JSON.stringify([
            {
              value: resp?.data?.user?.countryValue,
              label: resp?.data?.user?.countryLabel
            }
          ])
        );

        const { is_profile_completed: isProfileComplete, totalMatchCounts, isPlanExpired } = resp?.data?.user;
        if (isProfileComplete === 'YES') {
          // console.log('condition true');
          // const { goToNextStep } = this.props;
          // goToNextStep({ step: 6 });

          if (totalMatchCounts?.count > 0 && isPlanExpired) {
              history.push(AppConstants.routes.matchQueue);
          } else {
              history.push(AppConstants.routes.afterLogin);
          }
        } else {
          history.push(AppConstants.routes.registerSections);
        }
      }
      // else{
      //   toastr.error(`ERROR! Invalid login credentials`);
      // }
      setValues({ ...values, request: true });
    }
  };

  useEffect(async () => {
    setTimeout(() => {
      setIsSpinner(false);
    }, 1000);
  }, []);

  return (
    <>
      {isSpinner ? (
        <>
          <Spinner active={isSpinner} />
        </>
      ) : (
        <>
          <Helmet>
            <title>Login to Lovrican | Find Your African Soulmate</title>
            <meta
              name="description"
              content="Login to your Lovrican account and embark on your journey to find genuine love and happiness with a high-qualityl African professional in America. Embark on your journey to find love today!"
            />
            <meta name="keywords" content="Login to Lovrican" />
          </Helmet>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            limit={3}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <section className="loginWrapper text-light position-relative">
            <div className="container-fluid">
              <div className="row">
                {/* Mobile Header */}
                <HomeHeaderMobile />
                {/* End Mobile Header */}

                {/* <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <NavLink to={AppConstants.routes.home}>
                  <img src={logo} className="img-fluid" alt="Lovrican Logo" />
                </NavLink>
              </div>
            </div> */}

                <div className="reg-inner">
                  <div className="col-12">
                    <h1>
                      Welcome back to
                      <br />
                      Lovrican!
                    </h1>
                  </div>

                  <div
                    className="col-12 pr-0"
                    style={{ margin: '0 0 2.8rem 0' }}
                  >
                    <div className="reg-login ml-0 mb-0">
                      <input
                        className="login"
                        type="email"
                        name="user-email"
                        id="user-email"
                        placeholder="Enter your email"
                        autoComplete="off"
                        onChange={handleChange('email')}
                      />
                    </div>

                    <div>
                      <span className="srv-validation-message">
                        {errorValues.emailError}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-12 pr-0"
                    style={{ margin: '0 0 2.8rem 0' }}
                  >
                    <div className="reg-login ml-0 mb-0">
                      <input
                        className="login"
                        type="password"
                        name="user-password"
                        id="user-password"
                        placeholder="Enter your password"
                        autoComplete="off"
                        onChange={handleChange('password')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' && submitForm()
                        }
                      />
                    </div>
                    <div>
                      <span className="srv-validation-message">
                        {errorValues.passwordError}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 d-flex reg-login justify-content-end pr-0 border-0">
                    <span
                      className={`search-btn ${
                        !values.request ? 'hks-btn-disable' : ''
                      }`}
                      role="button"
                      tabIndex="0"
                      onClick={submitForm}
                      aria-hidden="true"
                    >
                      {/* <i className="fa fa-arrow-right" /> */}
                      <img
                        src={arrowIcon}
                        className="img-fluid"
                        alt="arrow icon"
                      />
                    </span>
                  </div>
                  <div className="col-12 text-right link-btn">
                    <p>
                      <span>Forgot Password?</span>
                      <NavLink to={AppConstants.routes.forgotPassword}>
                        <span className="link-color" role="button">
                          Reset
                        </span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default LoginPageComponent;
