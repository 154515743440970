import { React } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';

const planNotPurchasedPopup = ({ modalStatus, handleClose, popupType }) => {
  const history = useHistory();
  const { planDetails, current } = useSelector((state) => state.user);
  const handleClickPlan = () => {
    history.push({
      pathname: AppConstants.routes.plans,
      state: {
        daysRemaining: planDetails.daysRemaining,
        currentPlan: planDetails.currentPlan
      }
    });
  };

  const handleCancel = async () => {
    handleClose(false);
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">{popupType === 2 ? 'Activate Chat' : 'No Active Plan'}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Hey&nbsp;{`${current?.first_name || ''}`.trim()},&nbsp;
            {popupType === 2 ? 'Unlock the chat feature with an active subscription. Subscribe now to connect with your favorite matches and find your perfect match!' : 'you need an active Lovrican plan to access this functionality. Do you want to activate your plan now?'}
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleClickPlan()}
            >
              {popupType === 2 ? 'Activate Chat' : 'Activate Plan'}
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleCancel()}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default planNotPurchasedPopup;
