import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import '../../assets/scss/style.scss';
import '../../assets/scss/mobile.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast, ToastContainer } from 'react-toastify';
import webServices from '../../services/webServices';
import { isEmpty, isValidEmail } from '../../helpers/validator';
import { authCall } from '../../services';
import HowLovricanWorkPopup from './HowLovricanWorkPopup';
import ConfirmEmailPopup from './ConfirmEmailPopup';
import HomeHeaderMobile from '../../layouts/partial/homeHeaderMobile';
// import { loginPopupAction } from '../../redux/actions/home.actions';

import africanLogo from '../../assets/images/Lovrican-Logo_2.svg';
import step1 from '../../assets/images/step1-lovrican.svg';
import step2 from '../../assets/images/Step2-Lovrican.png';
import step3 from '../../assets/images/Step3-Lovrican.svg';
import step4 from '../../assets/images/Step4-Lovrican.png';
import step5 from '../../assets/images/Step5-Lovrican.svg';

// import nigeriaFlag from '../../assets/images/Nigeria-flag.png';
// import kenyaFlag from '../../assets/images/kenya-flag.png';
import meetsuccss from '../../assets/images/african_couple_haging_lovrican.webp'


import 'react-slideshow-image/dist/styles.css';

class HomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);
    this.state = {
      selected: 0,
      email: '',
      validRequest: false,
      howLovricanWorkMsg: false,
      confirmEmailMsg: false,
      otp: 0,
      randomCountry: ['Nigerians', 'Kenyans', 'Ghanians'],
      randomCulture: [
        'Igbo',
        'Oromo',
        'Kikuyu',
        'Ashanti',
        'Zulu',
        'Sukuma',
        'Hausa',
        'Baganda',
        'Luo',
        'Bakongo Mandinka',
        'Somali',
        'Yoruba'
      ],
      isScroll: false,
      homeConfigArr: []
      // customerFormActive: 0
    };
    Aos.init({ duration: 1000 });
  }

  async componentDidMount() {
    if (!localStorage.getItem('countryCulture')) {
      const response = await webServices.getCountryCulture();
      if (response.success) {
        localStorage.setItem('countryCulture', JSON.stringify(response.data));
      }
    }

    const homeConfigResult = await webServices.getHomeConfigData();
    if (homeConfigResult.success) {
      this.setState({ homeConfigArr: homeConfigResult.data });
    }
    
    // if (localStorage.getItem('countryCulture')) {
    //   const countryCulture = JSON.parse(localStorage.getItem('countryCulture'));
    //   setInterval(() => {
    //     this.setState({
    //       randomCountry: this._randomslice(countryCulture?.country, 5),
    //       randomCulture: this._randomslice(countryCulture?.culture, 12)
    //     });
    //   }, 7000);
    // }

    this.prev = window.scrollY;
    window.addEventListener('scroll', (e) => this.handleNavigation(e));
  }

  handleNavigation = (e) => {
    const { isScroll } = this.state;
    const window = e.currentTarget;
    if (!isScroll && this.prev < window.scrollY) {
      this.setState({ isScroll: true });
      if (localStorage.getItem('countryCulture')) {
        const countryCulture = JSON.parse(
          localStorage.getItem('countryCulture')
        );
        setInterval(() => {
          this.setState({
            randomCountry: this._randomslice(countryCulture?.country, 5),
            randomCulture: this._randomslice(countryCulture?.culture, 12)
          });
        }, 7000);
      }
    }
    // if (this.prev > window.scrollY) {
    //     console.log("scrolling up", this.prev);
    // } else if (this.prev < window.scrollY) {
    //     console.log("scrolling down", this.prev);
    // }
    this.prev = window.scrollY;
  };

  _randomslice = (ar, size) => {
    const newAr = [...ar];
    newAr.splice(Math.floor(Math.random() * ar.length), 1);
    return ar.length <= size + 1 ? newAr : this._randomslice(newAr, size);
  };

  validateForm = async () => {
    const { email } = this.state;
    if (isEmpty(email) || !isValidEmail(email)) {
      toast.dismiss();
      toast.error('Please enter a valid email.');
    } else {
      this.setState({ validRequest: true });
    }
  };

  handleClickConfirmEmailkMsg = async (e) => {
    e.preventDefault();
    await this.validateForm();
    const { validRequest, email } = this.state;
    if (validRequest) {
      const resp = await authCall('auth/action/send-otp-to-email', {
        email
      });
      if (resp && resp.success === true) {
        this.setState({
          confirmEmailMsg: true,
          otp: resp?.otp
        });
      }
    }
  };

  submitForm = async () => {
    await this.validateForm();
    const { history } = this.props;
    const { validRequest, email, otp } = this.state;
    if (validRequest) {
      this.setState({
        confirmEmailMsg: false
      });
      // history.push(`/register?email=${email}`);
      history.push({
        pathname: '/register',
        search: `?email=${email}`,
        state: { otp }
      });
    }
  };

  handleSelect = (key) => {
    this.setState({
      selected: key
    });
  };

  handleCancel = () => {
    this.setState({
      selected: 0
    });
  };

  handleCloseHowLovricanWorkMsg = () => {
    this.setState({
      howLovricanWorkMsg: false
    });
  };

  handleClickHowLovricanWorkMsg = () => {
    this.setState({
      howLovricanWorkMsg: true
    });
  };

  handleClickStump = () => {
    this.selectorRef.current.scrollIntoView();
    this.setState({
      // customerFormActive: 1
    });
  };

  // handleClickCustomerFormActive = (id) => {
  //   this.setState({
  //     customerFormActive: id
  //   });
  // };

  render() {
    const { history } = this.props;
    const {
      selected,
      // email,
      howLovricanWorkMsg,
      confirmEmailMsg,
      randomCountry,
      randomCulture,
      homeConfigArr
      // customerFormActive
    } = this.state;
    
    return (
      <>
        <Helmet>
          <title>Dating Site for African Professionals in America | Lovrican</title>
          <meta
            name="description"
            content="Lovrican helps you find your ideal African soulmate among the single African professionals in America. Lovrican specializes in high-quality African professionals you’d be proud to introduce to your family… people who share your culture, values, interests, and aspirations."
          />
          <meta name="keywords" content="African Dating Site in America" />
        </Helmet>


        {/* need to delete from developer side depend on many functions */}
        <div className="home d-none">
          <section className="love-arpiration love_arp_first text-center">
            <div className="container">
              <div className="max-container">
                {/* <h1 className="pb-2">
                  Meet African Singles living in the USA who are compatible with
                  you culturally and professionally.
                </h1> */}

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="animateEffect">
                      With Lovrican’s advanced user-screening capabilities and
                      intelligent matching algorithm, you can meet reliable,
                      serious, and diligent:
                      {/* <span>
                        {randomCountry.map((country, i) => (
                          <Link to="/register">
                            {country}
                            {randomCountry.length - 1 !== i ? ',' : ''}{' '}
                          </Link>
                        ))}
                      </span> */}
                      <div className="effectLink">
                        <span>
                          {randomCountry.map((country, i) => (
                            <Link to="/register">
                              {country}
                              {randomCountry.length - 1 !== i ? ',' : ''}{' '}
                            </Link>
                          ))}
                        </span>
                      </div>
                      or <Link to="/register"> other Africans</Link> today, who
                      meet your life-partner preferences and may be living
                      around the corner from you in the USA.
                    </h2>

                    <h2 className="animateEffect">
                      Whether you are looking for a quality life partner who is:
                      <div className="effectLink2">
                        <span>
                          {randomCulture.map((culture, i) => (
                            <Link to="/register">
                              {culture}
                              {randomCulture.length - 1 !== i ? ',' : ''}{' '}
                            </Link>
                          ))}
                        </span>
                      </div>
                      or from any other African community, we’ve got you
                      covered!
                    </h2>

                    <p>
                      Don’t take our word for it. Experience the Lovrican
                      difference for yourself.{' '}
                      <Link to="/register">
                        Create your profile and find love today!
                      </Link>{' '}
                      Registration is quick and easy.
                    </p>
                    <p>Don’t keep love waiting…</p>
                    <div
                      className="text-left mt-4"
                      aria-hidden="true"
                      role="button"
                      type="button"
                      onClick={() => {
                        history.push('/register');
                      }}
                    >
                      <span
                        className="round-button purple-theme"
                        role="button"
                        aria-hidden="true"
                      >
                        Get Started
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 text-left">
                    <h4>Lovrican True Love Guarantee</h4>
                    <h5>
                      We are confident that{' '}
                      <span>
                        you’ll soon find your special someone in Lovrican. In
                        fact, we guarantee* it!&nbsp;
                      </span>
                      Here’s the deal: Meet someone who is an exceptional fit
                      for you personally, culturally, and professionally within
                      6 months, or enjoy the service—free!—for another 6 months.
                    </h5>
                    <p className="note">
                      * Note: The guarantee is a limited time offer only. Act
                      now to secure your spot!
                    </p>
                    <div className="text-left">
                      <span
                        className="round-button purple-theme mr-4 mb-3 mb-md-2"
                        role="button"
                        aria-hidden="true"
                        onClick={() => {
                          history.push('/register');
                        }}
                      >
                        Get Guarantee
                      </span>
                      <span
                        className="round-button green-theme mb-0 mb-md-2"
                        role="button"
                        aria-hidden="true"
                        onClick={() => {
                          history.push('/gift');
                        }}
                      >
                        Be a Matchmaker
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 text-left last-home-section">
                    <h5>
                      Lovrican is the dating site{' '}
                      <span>
                        loved by singles who want to meet their special someone
                        but don’t like dating
                      </span>{' '}
                      or have no time to waste on conventional dating and
                      regular online dating. That is because Lovrican helps them
                      find their “happily ever after love” in the fewest dates
                      possible.
                    </h5>
                    <h5>
                      The secret sauce:&nbsp;
                      <span>
                        Lovrican specializes in the kind of people you would be
                        eager to introduce to your mama
                      </span>{' '}
                      – people who are a great fit for you personally,
                      culturally, and professionally.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="reasons-section">
                <div className="max-container">
                  <h3 className="head">
                    Here are 12 reasons Lovrican is <br />
                    different from other dating sites.
                  </h3>
                </div>
                <div className="row ">
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box1">
                      <div className="number">1</div>
                      Lovrican recognizes that{' '}
                      <span>
                        culture
                        <br />
                        and place of origin{' '}
                      </span>
                      are major <br />
                      factors in selecting a mate, <br />
                      especially in the{' '}
                      <span>
                        value-driven <br />
                        African society.
                      </span>{' '}
                      That is why we <br />
                      created Lovrican as a{' '}
                      <span>
                        courtship <br />
                        haven for millions of single <br />
                        African professionals
                      </span>{' '}
                      scattered <br />
                      across the globe.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box2">
                      <div className="number">2</div>
                      Lovrican{' '}
                      <span>
                        pre-screens <br />
                        every applicant
                      </span>{' '}
                      to <br />
                      ensure they are real, <br />
                      sincere, and a good <br />
                      fit for the Lovrican <br />
                      community:{' '}
                      <span>
                        {' '}
                        diligent,
                        <br />
                        kind, and caring.
                      </span>{' '}
                      This <br />
                      ensures you only meet <br />
                      high-quality people your
                      <br />
                      mama would approve of.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box3">
                      <div className="number">3</div>
                      Lovrican allows you to{' '}
                      <span>
                        specify the <br />
                        unique matching criteria
                      </span>{' '}
                      for your <br />
                      potential matches to ensure you <br />
                      only meet quality people of your <br />
                      type. Whether you are looking for a <br />
                      quality life partner who is{' '}
                      <Link to="/register">
                        Igbo, <br />
                        Oromo, Kikuyu, Ashanti, Zulu, <br />
                        Sukuma, Hausa, Baganda, Luo, <br />
                        Bakongo Mandinka, Somali, <br />
                        Yoruba,
                      </Link>{' '}
                      or from any other African <br />
                      community, we’ve got you covered!
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box4">
                      <div className="number">4</div>
                      Lovrican is{' '}
                      <span>
                        designed with <br />
                        your privacy in mind
                      </span>{' '}
                      and <br />
                      gives you the tools to <br />
                      control what you want to <br />
                      share and with whom. <br />
                      Unlike other sites,{' '}
                      <span>
                        your <br />
                        profile is not made <br />
                        public.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box5">
                      <div className="number">5</div>
                      Lovrican makes schedules and <br />
                      geographical barriers fade. It <br />
                      allows you to meet hundreds of <br />
                      professional African matches <br />
                      from the location of your choice <br />
                      around the world and at your <br />
                      own{' '}
                      <span>
                        convenience, no matter <br />
                        the time of day or night.
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box6">
                      <div className="number">6</div>
                      Lovrican{' '}
                      <span>
                        saves you the cost of <br />
                        time and resources
                      </span>{' '}
                      spent on <br />
                      drinks, dinners, movies, phone <br />
                      calls, fares, and so on that are <br />
                      associated with conventional <br />
                      dating and regular online dating. <br />
                      With Lovrican, you only spend <br />
                      your time and resources on the <br />
                      pre-screened candidate who <br />
                      matches your criteria.
                      <br />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box7">
                      <div className="number">7</div>
                      Lovrican was born out of love <br />
                      for Africa. We created the <br />
                      best technology to <br />
                      <span>
                        conveniently bring together <br />
                        Africa’s young men and <br />
                        women
                      </span>{' '}
                      wherever they are <br />
                      around the world so that they <br />
                      can find love more easily <br />
                      while fulfilling their dreams of <br />a successful
                      career.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box8">
                      <div className="number">8</div>
                      Lovrican helps you{' '}
                      <span>
                        avoid <br />
                        uncomfortable environments
                      </span>{' '}
                      like <br />
                      bars, lounges, and clubs where <br />
                      distractions and privacy might be <br />
                      an issue. Even a place of worship <br />
                      can be uncomfortable if your <br />
                      agenda is looking for a mate; and <br />
                      good luck to you if you are <br />
                      looking for your soulmate at a bar <br />
                      or in a club.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box9">
                      <div className="number">9</div>
                      In most African <br />
                      societies, men usually <br />
                      initiate relationships. <br />
                      <span>
                        Lovrican gives both <br />
                        genders equal <br />
                        opportunity to enjoy <br />
                        the adventure and <br />
                        surprise of finding love.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box10">
                      <div className="number">10</div>
                      Lovrican enables you to <br />
                      <span>
                        meet high-quality African <br />
                        professionals from the <br />
                        wide array of African <br />
                        cultures around the world:
                      </span>{' '}
                      <br />
                      from the Caribbean to the <br />
                      Americas, from Europe to <br />
                      Asia, and from Africa to <br />
                      Australia, all in one place.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box11">
                      <div className="number">11</div>
                      Lovrican believes in <br />
                      delivering{' '}
                      <span>
                        value to <br />
                        our customers;
                      </span>{' '}
                      unlike <br />
                      our competitors,{' '}
                      <span>
                        we <br />
                        charge less and give <br />
                        you more.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box12">
                      <div className="number">12</div>
                      Lovrican is{' '}
                      <span>
                        easy to use <br />
                        unlike other sites that <br />
                        complicate matchmaking.
                      </span>{' '}
                      <br />
                      You know what you want <br />
                      and Lovrican gives you a <br />
                      simple and flexible way of <br />
                      specifying that.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="personSection banner-bg">
            <div className="banner-caption love-text">
              <div className={`box ${selected === 11 ? 'active' : ''}`}>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="alert"
                  onClick={this.handleCancel}
                >
                  ×
                </button>
                <h1 data-aos="zoom-in-down">
                  Tailored Features for Your Convenience
                </h1>
                <Scrollbars className="scroll-height">
                  <div
                    // className="fixContent"
                    onClick={() => this.handleSelect(11)}
                    aria-hidden="true"
                    style={{ cursor: 'pointer' }}
                  >
                    <p className={`${selected === 11 ? '' : 'expandText'}`}>
                      <span data-aos="zoom-in-down">
                        {/* <span className="top">
                Wouldn’t it be wonderful to meet a culturally
                compatible special soulmate who is just as successful
                as you?
              </span> */}
                        <span className="bottom">
                          We’ve designed Lovrican with your convenience in mind.
                          Our user-friendly interface and intuitive features
                          make it easy for you to navigate and explore the
                          platform. From advanced search filters that allow you
                          to specify your desired criteria to personalized
                          matchmaking suggestions, we strive to ensure that your
                          experience on Lovrican is seamless and enjoyable.
                        </span>
                      </span>
                      <span className="bottom">
                        Additionally, our responsive design lets you stay
                        connected on the go, so you can browse profiles, chat
                        and arrange dates wherever and whenever you choose.
                      </span>
                    </p>
                  </div>
                </Scrollbars>
                <span
                  // data-aos="zoom-in-down"
                  className="dot_wrapper mt-2"
                  onClick={() => this.handleSelect(11)}
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                >
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </span>
              </div>
            </div>
          </section>
          <section className="this_is_your_comunity banner-bg">
            <div className="banner-caption love-text text-left">
              <h1 data-aos="zoom-in-down">This is Your Community</h1>
              <p data-aos="zoom-in-down">
                Lovrican is designed to meet the unique needs of highly
                successful African professionals, investors, entrepreneurs, and
                celebrities who are looking for a passionate long-term
                relationship with an equally successful partner.
              </p>
            </div>
          </section>
        </div>
        {/* need to delete from developer side depend on many functions */}

        <section className='main_home'>
          <section className='banner_section banner_bg'>
            <HomeHeaderMobile />
            <div className='banner_inner'>
              <div className='container'>
                <h1>Cultivating Love. <br />
                  Elevating Success.</h1>
                <p>Select your Home Country to start meeting <br />
                  affuent African singles in America</p>
                <div className='btn_group'>
                    {homeConfigArr.map(el => <Link className="btn" to={`/${el.iso_code}`}><img src={`/uploads/${el.flag_image}`} alt="" /> {el.country}</Link>)}
                </div>
              </div>
            </div>

          </section>
          
          
          <section className="love-arpiration love_arp_first text-left" style={{paddingTop: '4rem'}}>
            <div className="container">
              <div className='row tophead_newAdd'>
                <div className='col-md-4'>
                  <div className='wrap'>
                    <h3>100%</h3>
                    <p>Verified users.* Meet your kind, <br />
                      beautiful and brilliant single African <br />
                      professional today</p>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='wrap'>
                    <h3>97%</h3>
                    <p>of our members
                      are <br /> College Graduates**</p>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='wrap'>  <h3>Guaranteed</h3>
                    <p>Find your soulmate within 6 months, <br /> or enjoy an additional 6 months of <br /> Lovrican service free on us****</p>
                  </div>

                </div>
                <div className='col-md-12'>
                  <div className='membership_note'>*Users verified using Lovrican’s rigorous verification process</div>
                  <div className='membership_note'>**Lovrican data as of June 10, 2024</div>
                  <div className='membership_note'>***Guarantee is only applicable to subscribed users</div>
                </div>
              </div>
            </div>
          </section>
          
          <section className='your_ideal scroll_overflow'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-5'>
                  <h4>Meet Successful African Singles in America to Find Your Ideal Soulmate Who Enriches You Personally and Professionally</h4>
                </div>
                <div className='col-md-7'>
                  <img className='idelImg' src={meetsuccss} alt="" />
                  <div className='right'>
                    <h5>Lovrican is the premier online destination for affluent African singles in America</h5>
                    <p>Many Africans crave meeting an equally successful partner who gets them culturally and professionally. At Lovrican, we believe that true compatibility is the foundation of every successful relationship. Our advanced matching algorithm takes into account your interests, values, and aspirations, ensuring that you are connected with like- minded individuals who share your vision for the future. </p>
                    <p>
                      Whether you’re looking for someone who shares your definition of family values, a partner who appreciates your cultural heritage or a companion who understands the demands of your professional life, Lovrican is committed to helping you find that special someone.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='lovrican_work scroll_overflow'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-4'>
                  <h4>How <br />
                    Lovrican <br />
                    Works:</h4>
                </div>
                <div className='col-md-8'>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>1</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                        <img src={step1} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Express Your Interest and
                          Submit Your Basic Profile.</h5>
                        <p>Ready to embark on your journey to find love, success, and cultural connection? Joining Lovrican is as simple as expressing your interest and submitting your basic profile.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>2</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                        <img src={step2} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Expert Screening and
                          Approval.</h5>
                        <p>At Lovrican, we take great pride in our exclusive community. We review profiles with care and consideration, approving those that align with our high standards of success, affluence, and shared values.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>3</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                        <img src={step3} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Explore Curated and
                          Pre-Screened Matches.</h5>
                        <p>In your match queue, you’ll find a selection of exceptional individuals who have captured our attention, matching your preferences and aspirations.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>4</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                        <img src={step4} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Connect with Highly
                          Compatible Matches.</h5>
                        <p>Based on your unique profile, we’ll connect you with professional African singles in America who align with your vision for love and success.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>5</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                        <img src={step5} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Discover Your Special
                          African Soulmate.</h5>
                        <p>Prepare yourself for an incredible journey as Lovrican guides you toward discovering your special African soulmate.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='marqueeContainer'>
            <div className='marquee-container'>
              <div className='marquee-text'>  Open the door to a world of possibilities in finding lasting love and companionship!</div>
            </div>
          </section>

          <section className='ready_to_meet'>
            <div className='banner_inner'>
              <div className='container'>
                <h5>Ready to meet special <br />
                  African singles in America?</h5>

              </div>
            </div>

          </section>

          <section className='select_city'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12 col-lg-5 select_city_img'>
                  <img src={africanLogo} alt="" />
                </div>
                <div className='col-md-12 col-lg-7 mt-2'>
                  <p>Select your Home Country to start meeting <br />
                    affluent African singles in America:</p>
                  <div className='btn_group'>
                    {homeConfigArr.map(el => <Link className="btn" to={`/${el.iso_code}`}><img src={`/uploads/${el.flag_image}`} alt="" /> {el.country}</Link>)}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={3}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <HowLovricanWorkPopup
          modalStatus={howLovricanWorkMsg}
          handleClose={this.handleCloseHowLovricanWorkMsg}
        />

        {confirmEmailMsg && (
          <ConfirmEmailPopup
            modalStatus={confirmEmailMsg}
            handleConfirm={this.submitForm}
          />
        )}
      </>
    );
  }
}

// const mapStateToProps = (state) => ({ ...state });

// const mapDispatchToProps = (dispatch) => ({
//   //loginPopupActionLocal: (payload) => dispatch(loginPopupAction(payload))
// });

// export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
export default HomeComponent;
