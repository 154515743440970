import React, { useState, useEffect } from 'react';
import csc from 'country-state-city';
import Select from 'react-select';
import { toast } from 'react-toastify';
import lock from '../../assets/images/lock.svg';
import EditIcon from '../../assets/images/edit.svg';

const SelectComponent = ({ ...restProps }) => (
  <Select classNamePrefix="comon-scroll" {...restProps} />
);

const DropdownResidenceSection = ({
  label,
  value1,
  value2,
  value3,
  saveDetails,
  formControlName,
  isViewMode,
  isLock,
  isDisabled,
  helpNote = ''
}) => {
  const [localValueCountry, setLocalValueCountry] = useState(value1);
  const [localValueState, setLocalValueState] = useState(value2);
  const [localValueCity, setLocalValueCity] = useState(value3);

  const [countryList, setCountryList] = useState({});
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const goToEditMode = () => {
    if (!isViewMode && !isDisabled) {
      setIsEditMode(true);
    }
  };

  const getCountriesOptions = async () => {
    const data = await csc.getAllCountries();
    const options = data.map((d) => ({
      value: d.isoCode,
      label: `${d.name}`
    }));
    setCountryList(options);
  };

  const getStateOptions = async (country) => {
    const data = await csc.getStatesOfCountry(country);
    const options = data.map((d) => ({
      value: d.isoCode,
      label: d.name
    }));
    setStateList(options);
  };

  const getCityOptions = async (country, state) => {
    const data = await csc.getCitiesOfState(country, state);
    const options = data.map((d) => ({
      value: d.name,
      label: d.name
    }));
    setCityList(options);
  };

  useEffect(async () => {
    getCountriesOptions();
    if (localValueCountry?.value) {
      getStateOptions(localValueCountry?.value);
    }
    if (localValueCountry?.value && localValueState?.value) {
      getCityOptions(localValueCountry?.value, localValueState?.value);
    }
  }, []);

  const handleFormSubmit = (event) => {
    if (
      localValueCountry !== '' &&
      localValueState !== '' &&
      localValueCity !== null
    ) {
      event.preventDefault();
      (async () => {
        const response = await saveDetails({
          [formControlName + 1]: localValueCountry,
          [formControlName + 2]: localValueState,
          [formControlName + 3]: localValueCity
        });
        if (response && response.success) {
          toast.dismiss();
          toast.success('Updated successfully');
          setIsEditMode(false);
        }
      })();
    } else {
      // setIsEditMode(false);
    }
  };

  const handelCountryChange = async (value) => {
    getStateOptions(value.value);
    setLocalValueCountry(value);
    if (value.value !== localValueCountry?.value) {
      setLocalValueState(null);
      setLocalValueCity(null);
    }
  };

  const handelStateChange = async (value) => {
    getCityOptions(localValueCountry.value, value.value);
    setLocalValueState(value);
    if (value.value !== localValueState?.value) {
      setLocalValueCity(null);
    }
  };

  const handelCityChange = async (value) => {
    setLocalValueCity(value);
  };

  useEffect(() => {
    if (window.innerWidth <= 800 && window.innerHeight <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window]);

  return (
    <>
      <div className="card-box">
        <form onSubmit={handleFormSubmit}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={!isMobile && goToEditMode}
            aria-hidden="true"
          >
            <div className="card-header d-flex justify-content-between align-items-center">
              <span>{label}</span>
              {!isEditMode && !isViewMode && (
                <div
                  className={`edit ${isMobile ? '' : 'd-none'}`}
                  onClick={goToEditMode}
                  onKeyDown={(event) => event.key === 'Enter' && goToEditMode()}
                  role="button"
                  tabIndex="0"
                >
                  <img src={EditIcon} alt="Edit icon" />
                </div>
              )}
            </div>
            <div className="card-container">
              {!isEditMode &&
                (value1 ? (
                  <ul className="list-unstyled list1 mb-0">
                    <li>{value1.label}</li>
                  </ul>
                ) : (
                  <span className="text-danger-not">Not answered yet</span>
                ))}
              {isEditMode && (
                <SelectComponent
                  options={countryList}
                  value={localValueCountry}
                  onChange={(value) => handelCountryChange(value)}
                  onBlur={!isMobile && handleFormSubmit}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="card-box">
        <form onSubmit={handleFormSubmit}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={goToEditMode}
            aria-hidden="true"
          >
            <div className="card-container">
              {!isEditMode &&
                (value2 ? (
                  <ul className="list-unstyled list1 mb-0">
                    <li style={{ backgroundColor: '#00CEC7' }}>
                      {value2.label}
                    </li>
                  </ul>
                ) : (
                  <span className="text-danger-not">Not answered yet</span>
                ))}
              {isEditMode && (
                <SelectComponent
                  options={stateList}
                  value={localValueState}
                  onChange={(value) => handelStateChange(value)}
                  onBlur={handleFormSubmit}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      {(!isViewMode || formControlName !== 'origin') && (
        <div className="card-box lockBorder position-relative">
          {isLock && formControlName === 'origin' && (
            <div className="text-right lockSection">
              <img src={lock} alt="lock" />
            </div>
          )}
          <form onSubmit={handleFormSubmit}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={goToEditMode}
              aria-hidden="true"
            >
              <div className="card-container">
                {!isEditMode &&
                  (value2 ? (
                    <>
                      <ul className="list-unstyled list1 mb-0">
                        <li style={{ backgroundColor: '#FF7D09' }}>
                          {value3.label}
                        </li>
                      </ul>
                      {helpNote && (<div className='help-note'>Note: {helpNote}</div>)}
                    </>
                  ) : (
                    <span className="text-danger-not">Not answered yet</span>
                  ))}
                {isEditMode && (
                  <SelectComponent
                    options={cityList}
                    value={localValueCity}
                    onChange={handelCityChange}
                    onBlur={handleFormSubmit}
                  />
                )}
              </div>
            </div>
            {isEditMode && (
              <div className={`${isMobile ? '' : 'd-none'}`}>
                <button
                  className="btn mr-2 cancel-btn"
                  onClick={() => setIsEditMode(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn theme-btn"
                  type="submit"
                >
                  Save
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default DropdownResidenceSection;
