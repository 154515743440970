import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step5Component = ({ userDetails, masterData, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    industry: userDetails?.userDetails?.industry ? [{value: userDetails?.userDetails?.industry, label: userDetails?.userDetails?.industry}] : [],
    occupation: userDetails?.occupations ? [userDetails?.occupations] : [],
    jobTitle: userDetails?.userDetails?.jobTitle || ''
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        industry: values.industry.value,
        occupation: JSON.stringify(values.occupation),
        jobTitle: values.jobTitle
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step5" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Tell us about your profession. It helps your matches understand
                you better and enhances relatability
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join fifth_step">
            <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3">
              <Select
                placeholder="Industry"
                options={masterData?.industries}
                autoComplete="off"
                className="custom_input"
                values={values.industry}
                onChange={(value) =>
                  setValues({ ...values, industry: value[0] })
                }
              />
              {validator.message('industry', values.industry, 'required')}
            </div>

            <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3">
              <Select
                placeholder="Occupation"
                options={masterData?.occupations}
                autoComplete="off"
                className="custom_input"
                values={values.occupation}
                onChange={(value) =>
                  setValues({ ...values, occupation: value[0] })
                }
              />
              {validator.message('occupation', values.occupation, 'required')}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3">
              <input
                type="text"
                name="jobTitle"
                id="jobTitle"
                placeholder="Job title"
                className="custom_input"
                autoComplete="off"
                value={values.jobTitle}
                onChange={(e) =>
                  setValues({ ...values, jobTitle: e.target.value })
                }
              />
              {validator.message('jobTitle', values.jobTitle, 'required')}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step5Component;
