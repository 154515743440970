import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import webServices from './webServices';
import AppConstants from '../appConstants';

const AuthorizeNetPayment = (props) => {
  const { planId, planAmount, planType, selectedPlan, purchasedLikes } = props;

  const history = useHistory();
  const userDetails = useSelector((state) => (state.user && state.user.current) || null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalCloseBtn = () => setIsModalVisible(false);

  const handlePayNowClickEvent = () => {
    if (userDetails?.status !== 'ACTIVE') {
      history.push({ pathname: AppConstants.routes.congratulations });
    } else {
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    if (process.env.REACT_APP_ENV === 'production') {
      script.src = "https://js.authorize.net/v1/Accept.js";
    } else {
      script.src = "https://jstest.authorize.net/v1/Accept.js";
    }
    script.async = true; // Optional: load the script asynchronously
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <button
        type="button"
        className="btn btn-info theme-btn ml-3 r_color mb-2 mb-md-0"
        onClick={handlePayNowClickEvent}
      >
        Pay Now
      </button>

      <PaymentModalComponent isModalVisible={isModalVisible} handleClose={handleModalCloseBtn} planDetails={{ planId, planAmount, planType }} selectedPlan={selectedPlan} purchasedLikes={purchasedLikes} />
    </>
  );
}

const PaymentModalComponent = (props) => {
  const { isModalVisible, handleClose, planDetails, selectedPlan, purchasedLikes } = props;

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel paymentModel"
        show={isModalVisible}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">
              Add your credit card details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
            >
              &times;
            </button>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="loader-payment0">
          <PaymentFormComponent planDetails={planDetails} selectedPlan={selectedPlan} purchasedLikes={purchasedLikes} />
        </Modal.Body>
      </Modal>
    </>
  );
}

const PaymentFormComponent = (props) => {
  const { planDetails: { planId, planAmount, planType }, selectedPlan, purchasedLikes } = props;

  const history = useHistory();
  const [isPaying, setIsPaying] = useState(false);
  const [formData, setFormData] = useState({
    cardNumber: '',
    expirationDate: '',
    cardCode: '',
    amount: planAmount,
  });

  const [errors, setErrors] = useState({});

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length >= 3) {
      // value = value.slice(0, 2) + "/" + value.slice(2, 4); // Add slash after first two digits
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    }
    setFormData({
      ...formData, // Preserve other field values
      expirationDate: value, // Update current field
    });
  };

  const handleKeyDown = (e) => {
    // Prevent user from entering more than 5 characters (MM/YY)
    if (formData.expirationDate.length >= 5 && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, // Preserve other field values
      [name]: value, // Update current field
    });
  };

  const validateFields = () => {
    const newErrors = {};

    // Validate card number (should be 16 digits)
    if (!/^\d{16}$/.test(formData.cardNumber)) {
      newErrors.cardNumber = 'Card number must be 16 digits';
    }

    // Validate expiration date (MM/YY format and future date)
    const expDatePattern = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!expDatePattern.test(formData.expirationDate)) {
      newErrors.expirationDate = 'Expiration date must be in MM/YY format';
    } else {
      const [expMonth, expYear] = formData.expirationDate.split('/');
      const currentYear = new Date().getFullYear() % 100; // Last two digits of the year
      const currentMonth = new Date().getMonth() + 1; // Months are 0-based in JS

      if (parseInt(expYear, 10) < currentYear || (parseInt(expYear, 10) === currentYear && parseInt(expMonth, 10) < currentMonth)) {
        newErrors.expirationDate = 'Expiration date must be in the future';
      }
    }

    // Validate CVV code (3 or 4 digits)
    if (!/^\d{3,4}$/.test(formData.cardCode)) {
      newErrors.cardCode = 'CVV code must be 3 or 4 digits';
    }

    // Validate amount (must be a positive number)
    if (!/^\d+(\.\d{1,2})?$/.test(formData.amount) || parseFloat(formData.amount) <= 0) {
      newErrors.form = 'Amount must be a positive number';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateFields();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const authData = {
        clientKey: process.env.REACT_APP_AUTHORIZE_NET_CLIENT_KEY,
        apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_API_LOGIN_ID,
      };

      const cardData = {
        cardNumber: formData.cardNumber,
        month: formData.expirationDate.substring(0, 2),
        year: formData.expirationDate.substring(3, 5),
        cardCode: formData.cardCode,
      };

      setIsPaying(true);

      window.Accept.dispatchData({ authData, cardData }, async (response) => {
        if (response.messages.resultCode === 'Ok') {
          try {
            const result = await webServices.createAuthorizeNetOrder({
              planId,
              planType,
              amount: formData.amount,
              opaqueData: response.opaqueData,
              purchasedLikes
            });

            if (result?.success === true) {
              history.push({
                pathname: AppConstants.routes.paymentSuccess,
                state: { selectedPlan }
              });
            } else {
              history.push({
                pathname: AppConstants.routes.paymentFail,
                state: { selectedPlan }
              });
            }
          } catch (error) {
            setIsPaying(false);
            setErrors({ form: error.message });
          }
        } else {
          setIsPaying(false);
          setErrors({ form: response.messages.message[0].text });
        }
      });
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className="mb-3 col-12">
            <label htmlFor="cardNumber" className="form-label">Card Number</label>
            <input
              type="text"
              className={`form-control ${errors.cardNumber ? 'is-invalid' : ''}`}
              id="cardNumber"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleInputChange}
              autoComplete='off'
              placeholder='Enter Card Number'
              required
            />
            {errors.cardNumber && <div className="invalid-feedback">{errors.cardNumber}</div>}
          </div>

          <div className="col-md-6 mb-3 mt-0 mt-md-3">
            <label htmlFor="expirationDate" className="form-label">Expiration Date</label>
            <input
              type="text"
              className={`form-control ${errors.expirationDate ? 'is-invalid' : ''}`}
              id="expirationDate"
              name="expirationDate"
              value={formData.expirationDate}
              // onChange={handleInputChange}
              onChange={handleExpiryChange}
              onKeyDown={handleKeyDown}
              autoComplete='off'
              placeholder='MM/YY'
              required
            />
            {errors.expirationDate && <div className="invalid-feedback">{errors.expirationDate}</div>}
          </div>

          <div className="mb-3 col-md-6 mt-0 mt-md-3">
            <label htmlFor="cardCode" className="form-label">Security Code</label>
            <input
              type="text"
              className={`form-control ${errors.cardCode ? 'is-invalid' : ''}`}
              id="cardCode"
              name="cardCode"
              value={formData.cardCode}
              onChange={handleInputChange}
              autoComplete='off'
              placeholder='CVV'
              required
            />
            {errors.cardCode && <div className="invalid-feedback">{errors.cardCode}</div>}
          </div>

          {errors.form && <div className="text-danger mb-3">{errors.form}</div>}
        </div>
        <button type="submit" className="btn btn-primary btn-block mt-4" disabled={isPaying}>Pay ${formData.amount}</button>
      </form>
    </div>
  );

}

export default AuthorizeNetPayment;