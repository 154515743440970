import React, { useState, useCallback } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import AppConstants from '../../appConstants';
import logo from '../../assets/images/lovrican-africa.svg';

const HomeHeaderMobile = ({ pageLogo }) => {
  const history = useHistory();
  const [menuToggle, setMenuToggle] = useState(false);
  const isHomeLinkActive = useCallback(
    (match, { pathname }) => [AppConstants.routes.home].includes(pathname),
    []
  );

  const isAboutLinkActive = useCallback(
    (match, { pathname }) => [AppConstants.routes.about].includes(pathname),
    []
  );

  const isContactLinkActive = useCallback(
    (match, { pathname }) => [AppConstants.routes.contact].includes(pathname),
    []
  );

  const isFAQLinkActive = useCallback(
    (match, { pathname }) => [AppConstants.routes.faq].includes(pathname),
    []
  );

  return (
    <>
      <div className="common-top-logo">
        <div className="burger-menu">
          <div
            className="burger"
            aria-hidden="true"
            onClick={() => setMenuToggle(true)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          className="logo"
          // onClick={() => {
          //   history.push('/');
          // }}
          // onKeyDown={(event) => event.key === 'Enter' && history.push('/')}
          role="button"
          tabIndex="0"
        >
          <img
            src={pageLogo === undefined ? logo : `/uploads/${pageLogo}`}
            className="img-fluid"
            alt="Lovrican Logo"
            onClick={() => {
              history.push('/');
            }}
            onKeyDown={(event) => event.key === 'Enter' && history.push('/')}
            aria-hidden="true"
          />
        </div>
        {menuToggle && (
          <div className="navbar-mobile">
            <div className="menu-wrapper">
              <div className="d-block position-relative">
                <button
                  type="button"
                  className="close"
                  aria-hidden="true"
                  onClick={() => setMenuToggle(false)}
                >
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
              </div>
              <ul className="list-unstyled">
                <li>
                  <NavLink
                    to={AppConstants.routes.home}
                    activeClassName="active"
                    isActive={isHomeLinkActive}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={AppConstants.routes.about}
                    activeClassName="active"
                    isActive={isAboutLinkActive}
                    target="_blank"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://lovrican.com/blog/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <NavLink
                    to={AppConstants.routes.faq}
                    activeClassName="active"
                    isActive={isFAQLinkActive}
                    target="_blank"
                  >
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={AppConstants.routes.contact}
                    activeClassName="active"
                    isActive={isContactLinkActive}
                    target="_blank"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <div className="d-block link-btn">
                <span
                  className="round-button mt-0 active"
                  role="button"
                  tabIndex="0"
                  aria-hidden="true"
                  onClick={() => history.push(AppConstants.routes.register)}
                >
                  Join
                </span>
                <a
                  className="round-button mt-0 match-maker"
                  role="button"
                  tabIndex="0"
                  aria-hidden="true"
                  // onClick={() => history.push(AppConstants.routes.gift)}
                  href={AppConstants.routes.gift}
                  target="_blank"
                  rel="noreferrer"
                >
                  Be a Matchmaker
                </a>
                <span
                  className="round-button mt-0"
                  role="button"
                  tabIndex="0"
                  aria-hidden="true"
                  onClick={() => history.push(AppConstants.routes.login)}
                >
                  Login
                </span>
              </div>
              <div className="sideMenu-bottom d-none">
                <div className="Become-an-affiliate">Become an affiliate</div>
                <div className="Make-money-with-Lovr">
                  Make money with Lovrican
                </div>
              </div>
              <div className="sideMenu-bottom-blog">
                <a
                  href="https://lovrican.com/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  BLOG
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeHeaderMobile;
