import PropTypes from 'prop-types';
import React from 'react';
import AppConstants from '../appConstants';
// import '../assets/css/site.css';
import authService from '../services/authServices';
import Footer from './partial/footer';
// import HomeHeader from './partial/homeHeader';
import Spinner from '../components/general/spinner';

class HomeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nationality: null,
      isSpinner: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isSpinner: false });
    }, 1000);

    const { match } = this.props;
    if (
      match.path === AppConstants.routes.plans ||
      match.path === AppConstants.routes.rosePlans ||
      match.path.startsWith(AppConstants.routes.order)
    ) {
      return;
    }
    const { history } = this.props;
    authService.throwBackRev(history);
  }

  handleSetNationalityData = (data) => {
    this.setState({ nationality: data });
  };


  render() {
    const { children } = this.props;
    const { isSpinner, nationality } = this.state;

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, { setNationality: this.handleSetNationalityData })
    );

    return (
      <>
        {isSpinner ? (
          <Spinner active={isSpinner} />
        ) : (
          <div className="main-wrapper">
            {childrenWithProps}
            <Footer {...this.props} nationality={nationality} />
          </div>
        )}
      </>
    );
  }
}

HomeLayout.propType = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};

export default HomeLayout;
