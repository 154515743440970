import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { Modal } from 'react-bootstrap';
// import { toast } from 'react-toastify';
import webServices from '../../services/webServices';
// import authService from '../../services/authServices';
import AppConstants from '../../appConstants';

const AccountStatusSetting = ({ userSettings }) => {
  // const { logout } = authService;
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (props) => async (nextChecked) => {
    if (props === 'account_pause') {
      setChecked(nextChecked);
      // await webServices.updateUserSettings({
      //   [props]: nextChecked === true ? 'PAUSED' : 'ACTIVE'
      // });
      await webServices.updateUserSettings({ hold_new_match: nextChecked });
    }
  };

  useEffect(async () => {
    setChecked(userSettings?.hold_new_match === '1');
  }, [userSettings]);

  const handleDelete = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleFinalDelete = async () => {
    history.push(AppConstants.routes.feedbacks);

    // await webServices.updateUserSettings({
    //   account_delete: 'DELETED'
    // });
    // setShow(false);
    // toast.success('Your account has been deleted successfully!');
    // logout(history);
  };
  return (
    <>
      <div className="card-box pt-3 ">
        <div className="row settingEmail">
          <label className="d-block w-100" htmlFor="label">
            Pause your account
          </label>
          <div className="reminder">
            <div className="custom-switch settingPage mb-4 inner-Switch mt-2">
              <Switch
                checked={checked}
                onChange={handleChange('account_pause')}
                handleDiameter={28}
                offColor="#634A65"
                onColor="#B800E0"
                offHandleColor="#fff"
                onHandleColor="#E4DCE6"
                height={40}
                width={120}
                uncheckedIcon={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      color: '#fff',
                      fontSize: 18,
                      fontWeight: 'bold',
                      paddingRight: 20,
                      paddingTop: 2
                    }}
                  >
                    OFF
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: '#fff',
                      paddingRight: 5,
                      paddingTop: 2,
                      marginLeft: '35px'
                    }}
                  >
                    ON
                  </div>
                }
                className="react-switch"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-box pt-2 ">
        <div className="row settingEmail pb-3">
          <label className="d-block w-100" htmlFor="label">
            Delete your account
          </label>
          <div className="reminder">
            <button
              type="button"
              className="btn btn-outline-danger delete-account"
              onClick={handleDelete}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
      <Modal
        className="alertPhoto"
        show={show}
        onHide={handleClose}
        size="sm"
        centered
      >
        <Modal.Body className="text-center ">
          <p>Are you sure, you want to delete account?</p>
          <div className="btn-group mr-2" role="group" aria-label="First group">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleClose}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleFinalDelete}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountStatusSetting;
