import React from 'react';
import { useHistory } from 'react-router-dom';
import NotFoundComponent from '../components/errors/404';
import HomeComponent from '../components/home/HomeComponent';
import HomeComponent2 from '../components/home/HomeComponent2';
import AffiliateComponent from '../components/home/affiliates/AffiliateComponent';
import OrderComponent from '../components/home/Order';
import PlansComponent from '../components/home/Plans';
import RosePlansComponent from '../components/home/RosePlans';
import ErrorLayout from '../layouts/error';
import HomeLayout from '../layouts/home';
import InnerLayout from '../layouts/inner';
import StaticLayout from '../layouts/static';
import BillingDetailsPage from './billingDetails';
import ChatMain from './Chat';
import EditProfilePage from './editProfile';
import EditProfilePhotosPage from './editProfilePhotos';
import MatchesPage from './matches';
import MatcheQueuePage from './matchQueue';
import PublicProfilePage from './publicProfile';
import RegisterPageComponent from './register';
import RegisterSectionsComponent from './registerSections';
import GiftPageComponent from './gift';
import LoginPageComponent from './login';
import PasswordComponent from './password';
import SettingsPage from './settings';
import CommunityStandardsPage from './static/communityStandards';
import ContactUsPage from './static/contactUs';
import FaqPage from './static/faq';
import AboutUsPage from './static/aboutUs';
import PrivacyPolicyPage from './static/privacyPolicy';
import SafetyTipsPage from './static/safetyTips';
import ServiceTermsPage from './static/serviceTerms';
import ViewProfilePage from './viewProfile';
import ViewProfilePhotosPage from './viewProfilePhotos';
import PlansViewPage from './plansView';
import RegisterSuccessComponent from '../components/home/RegisterSuccessComponent';
import ForgotPasswordComponent from './forgoPassword';
import LikesPlansComponent from '../components/home/LikesPlans';
import RoseHistoryPage from './roseHistory';
import LikeHistoryPage from './likeHistory';
import SuccessDetailsComponent from '../components/home/gift/SuccessDetailsComponent';
import FaliureDetailsComponent from '../components/home/gift/FaliureDetailsComponent';
import GiftEditComponent from '../components/home/gift/GiftEditComponent';
import EmailVerifyComponent from '../components/home/EmailVerifyComponent';
import Section2SuccessComponent from './registerSections/Section2SuccessComponent';
import AffiliateAccountTypeComponent from '../components/home/affiliates/AffiliateAccountTypeComponent';
import AccountDeleteFeedbacksPage from '../components/accountDeleteFeedbacks';
import RewardsFeedbacksPage from '../components/rewardsFeedbacks';
import ViewUsersFromOtherCountry from './viewUsersFromOtherCountry';


// Admin
import InnerLayoutAdmin from '../layouts/innerAdmin';
import LoginComponent from '../components/admin/LoginComponent';
import DashboardComponent from '../components/admin/DashboardComponent';

// import UserGraphComponent from '../components/admin/UserGraphComponent';
// import UserListComponent from '../components/admin/UserListComponent';
// import ContactUserListComponent from '../components/admin/ContactUserListComponent';
// import UserProfileComponent from '../components/admin/UserProfileComponent';
// import UserPhotosComponent from '../components/admin/UserPhotosComponent';
// import UserEditProfileComponent from '../components/admin/UserEditProfileComponent';
// import UserAddedDataListComponent from '../components/admin/UserAddedDataListComponent';
// import UserAddedDataEditComponent from '../components/admin/UserAddedDataEditComponent';


// Affiliate
import AffiliateLayout from '../layouts/affiliateLayout';
import AffiliateInnerLayout from '../layouts/AffiliateInnerLayout';
import AffiliateRegisterComponent from '../components/home/affiliates/AffiliateRegisterComponent';
import AffiliateRegisterActionComponent from '../components/home/affiliates/AffiliateRegisterActionComponent';
import AffiliateLoginComponent from '../components/home/affiliates/AffiliateLoginComponent';
import AffiliateRegStep3Component from '../components/home/affiliates/AffiliateRegStep3';
import AffiliateRegSuccessComponent from '../components/home/affiliates/AffiliateRegSuccess';
import PublicProfilePageAffilaite from '../components/home/affiliates/dashboard/PublicProfile';
import SettingsPageAffiliate from '../components/home/affiliates/dashboard/Settings';
import DashboardPage from '../components/home/affiliates/dashboard/Dashboard';
import EarningsPage from '../components/home/affiliates/dashboard/Earnings';
import ReferalsPage from '../components/home/affiliates/dashboard/Referals';
import ContactPage from '../components/home/affiliates/dashboard/Contact';


import PayPalCheckoutPage from '../services/PayPalCheckout';
import PaymentSuccessPage from '../components/home/Order/PaymentSuccess';
import PaymentFailurePage from '../components/home/Order/PaymentFailure';


export const Home = (props) => {
  const history = useHistory();
  return (
    <HomeLayout {...props} history={history}>
      <HomeComponent {...props} history={history} />
    </HomeLayout>
  );
};

export const Home2 = (props) => {
  const history = useHistory();
  return (
    <HomeLayout {...props} history={history}>
      <HomeComponent2 {...props} history={history} />
    </HomeLayout>
  );
};

export const Affiliate = (props) => {
  const history = useHistory();
  return (
    <HomeLayout {...props} history={history}>
      <AffiliateComponent {...props} history={history} />
    </HomeLayout>
  );
};

export const AffiliateAccountType = (props) => {
  const history = useHistory();
  return (
    <AffiliateLayout {...props} history={history}>
      <AffiliateAccountTypeComponent {...props} history={history} />
    </AffiliateLayout>
  );
};

export const AffiliateRegister = (props) => {
  const history = useHistory();
  return (
    <AffiliateLayout {...props} history={history}>
      <AffiliateRegisterComponent {...props} history={history} />
    </AffiliateLayout>
  );
};

export const AffiliateRegisterSections = (props) => {
  const history = useHistory();
  return (
    <AffiliateLayout {...props} history={history}>
      <AffiliateRegisterActionComponent {...props} history={history} />
    </AffiliateLayout>
  );
};

export const AffiliateLogin = (props) => {
  const history = useHistory();
  return (
    <AffiliateLayout {...props} history={history}>
      <AffiliateLoginComponent {...props} history={history} />
    </AffiliateLayout>
  );
};

export const AffiliateRegStep3 = (props) => {
  const history = useHistory();
  return (
    <AffiliateLayout {...props} history={history}>
      <AffiliateRegStep3Component {...props} history={history} />
    </AffiliateLayout>
  );
};

export const AffiliateRegSuccess = (props) => {
  const history = useHistory();
  return (
    <AffiliateLayout {...props} history={history}>
      <AffiliateRegSuccessComponent {...props} history={history} />
    </AffiliateLayout>
  );
};

export const PublicProfileAffiliate = (props) => {
  const history = useHistory();
  return (
    <AffiliateInnerLayout {...props} history={history}>
      <PublicProfilePageAffilaite {...props} history={history} />
    </AffiliateInnerLayout>
  );
};

export const SettingAffiliate = (props) => {
  const history = useHistory();
  return (
    <AffiliateInnerLayout {...props} history={history}>
      <SettingsPageAffiliate {...props} history={history} />
    </AffiliateInnerLayout>
  );
};

export const DashboardAffiliate = (props) => {
  const history = useHistory();
  return (
    <AffiliateInnerLayout {...props} history={history}>
      <DashboardPage {...props} history={history} />
    </AffiliateInnerLayout>
  );
};

export const EarningsAffiliate = (props) => {
  const history = useHistory();
  return (
    <AffiliateInnerLayout {...props} history={history}>
      <EarningsPage {...props} history={history} />
    </AffiliateInnerLayout>
  );
};

export const ReferalsAffiliate = (props) => {
  const history = useHistory();
  return (
    <AffiliateInnerLayout {...props} history={history}>
      <ReferalsPage {...props} history={history} />
    </AffiliateInnerLayout>
  );
};

export const ContactAffiliate = (props) => {
  const history = useHistory();
  return (
    <AffiliateInnerLayout {...props} history={history}>
      <ContactPage {...props} history={history} />
    </AffiliateInnerLayout>
  );
};

export const Plans = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <PlansComponent {...props} history={history} />
    </InnerLayout>
  );
};


export const PayPalCheckout = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <PayPalCheckoutPage {...props} history={history} />
    </InnerLayout>
  );
};

export const PaymentSuccess = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <PaymentSuccessPage {...props} history={history} />
    </InnerLayout>
  );
};

export const PaymentFail = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <PaymentFailurePage {...props} history={history} />
    </InnerLayout>
  );
};


export const LikesPlans = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <LikesPlansComponent {...props} history={history} />
    </InnerLayout>
  );
};

export const RosePlans = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <RosePlansComponent {...props} history={history} />
    </InnerLayout>
  );
};

export const Order = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history}>
      <OrderComponent {...props} history={history} />
    </InnerLayout>
  );
};

export const Login = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <LoginPageComponent {...props} history={history} />
    </div>
  );
};

export const Password = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <PasswordComponent {...props} history={history} />
    </div>
  );
};

export const ForgotPassword = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <ForgotPasswordComponent {...props} history={history} />
    </div>
  );
};

export const EmailVerification = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <EmailVerifyComponent {...props} history={history} />
    </div>
  );
};

export const Register = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <RegisterPageComponent {...props} history={history} />
    </div>
  );
};

export const RegisterSections = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <RegisterSectionsComponent {...props} history={history} />
    </div>
  );
};


export const Congratulations = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <Section2SuccessComponent {...props} history={history} />
    </div>
  );
};

export const Gift = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <GiftPageComponent {...props} history={history} />
    </div>
  );
};

export const GiftEdit = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <GiftEditComponent {...props} history={history} />
    </div>
  );
};

export const GiftSuccess = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <SuccessDetailsComponent {...props} history={history} />
    </div>
  );
};

export const GiftFail = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <FaliureDetailsComponent {...props} history={history} />
    </div>
  );
};


export const Faq = (props) => {
  const history = useHistory();
  return (
    <StaticLayout {...props} history={history}>
      <FaqPage {...props} history={history} />
    </StaticLayout>
  );
};

export const ContactUs = (props) => {
  const history = useHistory();
  return (
    <StaticLayout {...props} history={history}>
      <ContactUsPage {...props} history={history} />
    </StaticLayout>
  );
};

export const PrivacyPolicy = (props) => {
  const history = useHistory();
  return (
    <StaticLayout {...props} history={history}>
      <PrivacyPolicyPage {...props} history={history} />
    </StaticLayout>
  );
};

export const AboutUs = (props) => {
  const history = useHistory();
  return (
    <HomeLayout {...props} history={history}>
      <AboutUsPage {...props} history={history} />
    </HomeLayout>
  );
};

export const SafetyTips = (props) => {
  const history = useHistory();
  return (
    <StaticLayout {...props} history={history}>
      <SafetyTipsPage {...props} history={history} />
    </StaticLayout>
  );
};

export const CommunityStandards = (props) => {
  const history = useHistory();
  return (
    <StaticLayout {...props} history={history}>
      <CommunityStandardsPage {...props} history={history} />
    </StaticLayout>
  );
};

export const ServiceTerms = (props) => {
  const history = useHistory();
  return (
    <StaticLayout {...props} history={history}>
      <ServiceTermsPage {...props} history={history} />
    </StaticLayout>
  );
};

export const Matches = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history}>
      <MatchesPage {...props} history={history} />
    </InnerLayout>
  );
};

export const MatchQueue = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history}>
      <MatcheQueuePage {...props} history={history} />
    </InnerLayout>
  );
};

export const RoseHistory = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history}>
      <RoseHistoryPage {...props} history={history} />
    </InnerLayout>
  );
};

export const LikeHistory = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <LikeHistoryPage {...props} history={history} />
    </InnerLayout>
  );
};

export const PublicProfile = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <PublicProfilePage {...props} history={history} />
    </InnerLayout>
  );
};


// export const Chat = (props) => <ChatMain {...props} />;
export const Chat = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history}>
      <ChatMain {...props} />
    </InnerLayout>
  );
};

export const EditProfile = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <EditProfilePage {...props} history={history} />
    </InnerLayout>
  );
};

export const ViewProfile = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <ViewProfilePage {...props} history={history} />
    </InnerLayout>
  );
};

export const EditProfilePhotos = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <EditProfilePhotosPage {...props} history={history} />
    </InnerLayout>
  );
};

export const ViewProfilePhotos = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <ViewProfilePhotosPage {...props} history={history} />
    </InnerLayout>
  );
};

export const Settings = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <SettingsPage {...props} history={history} />
    </InnerLayout>
  );
};

export const PlansView = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <PlansViewPage {...props} history={history} />
    </InnerLayout>
  );
};

export const BillingDetails = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <BillingDetailsPage {...props} history={history} />
    </InnerLayout>
  );
};


export const AccountDeleteFeedbacks = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <AccountDeleteFeedbacksPage {...props} history={history} />
    </InnerLayout>
  );
};

export const RewardsFeedbacks = (props) => {
  const history = useHistory();
  return (
    <InnerLayout {...props} history={history} sidebar={2}>
      <RewardsFeedbacksPage {...props} history={history} />
    </InnerLayout>
  );
};

export const RegisterSuccess = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <RegisterSuccessComponent {...props} history={history} />
    </div>
  );
};

export const PageNotFound = (props) => (
  <ErrorLayout {...props}>
    <NotFoundComponent {...props} />
  </ErrorLayout>
);

// ADMIN's
export const AdminLogin = (props) => {
  const history = useHistory();
  return <LoginComponent {...props} history={history} />;
};

export const AdminDashboard = (props) => {
  const history = useHistory();
  return (
    <InnerLayoutAdmin {...props} history={history}>
      <DashboardComponent {...props} history={history} />
    </InnerLayoutAdmin>
  );
};

export const matchQueueCountryPreference = (props) => {
  const history = useHistory();
  return (
    <div className="muli-fonts">
      <ViewUsersFromOtherCountry {...props} history={history} />
    </div>
  );
};
